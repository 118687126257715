import { MDBBtn } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../data/constants'

import back from '../../../../assets/img/arrow-back.svg'

function OrderNumber({ orderId }) {
  return (
    <>
      <div className='d-none d-md-block'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <Link to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS}>
          <MDBBtn className='btn-arrow'>
            <img src={back} alt='back'></img>
          </MDBBtn>
          </Link>
          
          <h3 className='fw-bold mb-0 mx-auto pr-5'>Bestellung {orderId}</h3>
        </div>
      </div>
    </>
  )
}

export default OrderNumber
