import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardNavigationDesktopOnly from '../components/DashboardNavigationDesktopOnly'
import Content from '../components/Content'
import DashboardMain from '../components/DashboardMain'
import OrderNumberMobile from '../features/dashboard/orderDetails/components/OrderNumberMobile'
import DashboardNavigationMobile from '../components/DashboardNavigationMobile'


function DashboardNavigationOnlyDesktopLayout( {children} ) {
  return (
    <Fragment>
      <DashboardNavigationDesktopOnly />
      <OrderNumberMobile />
      <DashboardMain>
        <Content>
          {children}
          <Outlet />
        </Content>
        <DashboardNavigationMobile />
        </DashboardMain>
    </Fragment>
  )
}

export default DashboardNavigationOnlyDesktopLayout