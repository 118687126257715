import { Fragment } from 'react'
import { MDBFooter } from 'mdb-react-ui-kit'

import { Link } from 'react-router-dom'

import classes from './DefaultFooter.module.css'

import germanStartups from '../assets/img/deutsche-startups.svg'
import facebook from '../assets/img/Facebook.svg'
import instagram from '../assets/img/Instagram.svg'
import twitter from '../assets/img/Twitter.svg'
import youtube from '../assets/img/youtube.svg'

function DefaultFooter() {
  return (
    <Fragment>
      <MDBFooter className={`${classes.mainFooter} bg-white`}>
        <div className='container'>
          <div className='row'>
            <div className={`col-lg-3 col-sm-6 ${classes.mainFooterBox}`}>
              <p>Info</p>
              <Link to='/widerrufsbelehrung'>Wiederrufsbelehrung</Link>
              {/* <Link to='/gebuhren'>Gebühren</Link> */}
            </div>
            <div className={`col-lg-3 col-sm-6 ${classes.mainFooterBox}`}>
              <p>Unternehmen</p>
              {/* <Link to='/uber-uns'>Über uns</Link> */}
              <Link to='/kontakt'>Kontakt</Link>
              <Link to='/impressum'>Impressum</Link>
              <Link to='/'>Datenschutz</Link>
              <a href='../docs/KREDU_AGB_10_2023.pdf'>AGB Kredu GmbH</a>
            </div>
            <div className={`col-lg-3 col-sm-6 ${classes.mainFooterBox}`}>
              <a href='https://www.kredu.de/themen/'>Themen</a>
            </div>
            <div className={`col-lg-3 col-sm-6 ${classes.mainFooterBox}`}>
              <p>KREDU GMBH</p>
              <p>
                Bundesallee 220
                <br />
                D-10719 Berlin, Deutschland
                <a href='tel:+493039915015'>+49 (0)30 399 15 0 15</a>
                <a href='mailto:support@kredu.de'>support@kredu.de</a>
                <Link to='/'>www.kredu.de</Link>
              </p>
            </div>
          </div>
          <div className={`row ${classes.mainFooterRow}`}>
            <div className={`col-sm-4 ${classes.copyrightBox}`}>
              <p className='py-3'>Copyright &copy;{new Date().getFullYear()} Kredu</p>
            </div>
            <div className={`col-sm-4 text-center ${classes.logoBox}`}>
              <img src={germanStartups} alt='logo' className='py-3' />
            </div>
            <div className={`col-sm-4 text-center ${classes.socialMedia}`}>
              {/* <a className='py-3' href='#'>
                <img src={facebook} aria-hidden='true' alt='facebook'></img>
              </a>
              <a className='py-3' href='#'>
              <img src={instagram} aria-hidden='true' alt='instagram'></img>
              </a>
              <a className='py-3' href='#'>
                <img src={twitter} aria-hidden='true' alt='twitter'></img>
              </a>
              <a className='py-3' href='#'>
              <img src={youtube} aria-hidden='true' alt='twitter'></img>
              </a> */}
            </div>
          </div>
        </div>
      </MDBFooter>
    </Fragment>
  )
}

export default DefaultFooter
