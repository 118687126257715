import classes from './DashboardPersonalInformationLoadingError.module.css'

import errorImg from '../../src/assets/img/error-img.svg'

function DashboardPersonalInformationLoadingError() {
  function handleRefreshButtonClick() {
    window.location.reload()
  }

  return (
    <div className='text-center row'>
      <div className={` ${classes.errorBox} mx-auto col`}>
        <img
          src={errorImg}
          alt='Error'
          className='img-fluid mb-4 px-4 py-3 px-lg-5'
        ></img>
        <h4 className='fw-bold mt-2'>
          Leider war es uns nicht möglich, alle Daten zu laden.
        </h4>{' '}
        <p>
          Versuche, die Seite zu aktualisieren, und wenn das Problem weiterhin
          auftritt, kontaktiere unseren Support unter&nbsp;
          <a
            href='mailto:support@kredu.de'
            className='text-decoration-underline'
          >
            support@kredu.de
          </a>
        </p>
        <button onClick={handleRefreshButtonClick} className={classes.link}>
          {' '}
          Erneut versuchen
        </button>
      </div>
    </div>
  )
}

export default DashboardPersonalInformationLoadingError
