import classes from './DashboardMain.module.css'

function DashboardMain( {children} ) {
  return (
    <div className={`${classes.purchaseHistory} h-100 py-lg-5 py-3 mb-lg-5 mt-5 container`}>
      {children}
    </div>
  )
}

export default DashboardMain