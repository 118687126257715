import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import RegistrationMain from '../components/RegistrationMain'
import RegistrationProgressDesktop from '../components/RegistrationProgressDesktop'
import RegistrationProgressMobile from '../components/RegistrationProgressMobile'
import Content from '../components/Content'

function RegistrationLayout( {children} ) {
  return (
    <Fragment>
      <RegistrationProgressMobile />
      <RegistrationMain>
        <RegistrationProgressDesktop />
        <Content>
          {children}
          <Outlet />
        </Content>
      </RegistrationMain>
    </Fragment>
  )
}

export default RegistrationLayout