import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import userService from '../../services/userService'
import FormError from '../../components/FormError'
import { MDBInputGroup, MDBBtn, MDBInput, MDBSpinner } from 'mdb-react-ui-kit'
import { ROUTES } from '../../data/constants'
import { Link } from 'react-router-dom'

import classes from './RegistrationOTP.module.css'

import back from '../../assets/img/arrow-back-blue.svg'

function RegistrationOTP() {
  const [otp, setOtp] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()

  const handleOtpChange = (e) => {
    setOtp(e.target.value.toString())
  }

  const handleGoBackClick = (e) => {
    e.preventDefault()
    navigate(
      ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER
    )
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    userService
      .confirmMobilePhoneNumber(user.token, otp)
      .then((response) => {
        localStorage.removeItem('is-otp-sent')
        navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_PERSONAL_DATA)
      })
      .catch((error) => {
        setIsError(true)
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data === 'Invalid token.'
        ) {
          setErrorMessage('Der eingegebene Bestätigungscode ist ungültig.')
        }
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Handynummer</title>
        <meta name='description' content='KREDU - Handynummer' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='pt-lg-3 container'>
        <div
          className={`${classes.registrationFormBox} d-md-inline-block mx-auto me-lg-0 ms-lg-3`}
        >
          <h2 className='mb-3 d-none d-md-block'>Handynummer</h2>
          <p className='pt-4 pt-md-0 text-start ps-3 ps-md-0 mb-5 fw-normal'>
            Bitte gib den Bestätigungscode ein, den du{' '}
            <br className='d-none d-md-block' />
            per SMS erhalten hast.
          </p>
          <form onSubmit={handleFormSubmit} className='form-box px-3 px-md-0'>
            <MDBInputGroup className='mb-4 w-100'>
              <MDBInput
                label='Bestätigungscode'
                type='text'
                pattern='\d*'
                maxLength='6'
                required
                onChange={handleOtpChange}
                className={`${classes.formControl} form-contol w-100 ps-2 me-3`}
              />
            </MDBInputGroup>
            <p className='fw-normal mb-3'>
              Bestätigungscode nicht erhalten? Gehe zurück und versuche es noch
              einmal.
            </p>
            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              onClick={handleGoBackClick}
              className={`${classes.btnBack} font-blue btn-back mb-2 mt-2 fw-bold  me-5 d-none d-md-inline-block`}
              color='tertiary'
              rippleColor='light'
              type='button'
            >
              <img src={back} alt='back' className='me-3 d-inline-block' />
              <span>Zurück</span>
            </MDBBtn>
            <MDBBtn
              type='submit'
              className={`${classes.btnYellow} btn-yellow btn mt-2 waves-effect`}
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Weiter
            </MDBBtn>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationOTP
