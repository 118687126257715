import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import appRejected from '../../assets/img/rejected-icon.svg'

import classes from './BnplOrderDeclined.module.css'

function BnplOrderDeclined() {
  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Der Antrag wurde abgelehnt</title>
          <meta name="description" content="KREDU - Der Antrag wurde abgelehnt" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='vh-100 pt-lg-5 text-center container'>
        <div className='container-heigh text-center row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <img src={appRejected} alt='appRejected' className='my-5'></img>
              <h2 className='fw-bold mb-4'>
                Dein Antrag auf Kauf auf Rechnung wurde abgelehnt
              </h2>
              <p className='px-xl-5 px-sm-3'>
              Leider können wir den Kauf auf Rechnung nicht anbieten.
                <br />
                <br />
                Du hast aber die Möglichkeit, deine Wunschprodukte per Sofortüberweisung zu bezahlen.
              </p>
              <Link
                to='/warenkorb'
                className={`${classes.fontBlue} text-uppercase`}
              >
                zurück zum Warenkorb
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BnplOrderDeclined
