import { Fragment, useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { MDBBtn, MDBSelect, MDBSpinner } from 'mdb-react-ui-kit'
import requiredDocumentsService from '../services/requiredDocumentsService'
import uploadedDocumentsService from '../services/uploadedDocumentsService'
import FormError from '../../../components/FormError'
import DocumentsList from './components/DocumentsList'

import classes from './assets/css/AdditionalDocumentsUpload.module.css'

function AdditionalDocumentsUpload() {
  const [file, setFile] = useState('')
  const [requiredDocuments, setRequiredDocuments] = useState([])
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [documentType, setDocumentType] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const { user } = useSelector((state) => state.auth)

  const fileInputRef = useRef()

  useEffect(() => {
    requiredDocumentsService
      .getRequiredDocuments(user.token)
      .then((response) => {
        setRequiredDocuments(response)
        setDocumentType(response[0])
      })
      .catch((error) => {})

    uploadedDocumentsService
      .getUploadedDocuments(user.token)
      .then((response) => {
        setUploadedDocuments(response)
      })
      .catch((error) => {})
  }, [])

  const handleFileInputChange = (e) => {
    setFile(e.target.files[0])
    setIsButtonDisabled(false)
  }

  const handleUploadRequiredDocument = async (e) => {
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)
    setIsButtonDisabled(true)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('documentType', documentType)

    try {
      const result = await uploadedDocumentsService.uploadRequiredDocument(
        user.token,
        formData
      )      

      if (result === '') {
        setFile('')
        fileInputRef.current.value = null
        try {
          const result = await uploadedDocumentsService.getUploadedDocuments(
            user.token
          )
          setUploadedDocuments(result)
        } catch {
          setIsError(true)
        }
      } else if (result === 'FILE_TOO_BIG') {
        setIsError(true)
        setErrorMessage(
          'Die hochgeladene Datei ist zu groß. Die maximale Dateigröße beträgt 15 MB.'
        )        
      } else if (result === 'UNSUPPORTED_FILE_TYPE') {
        setIsError(true)
        setErrorMessage('Die hochgeladene Datei hat ein falsches Format.')        
      } else {
        setIsError(true)        
      }
    } catch (error) {
      setIsError(true)      
    }

    setIsButtonDisabled(false)
    setIsLoading(false)
  }

  function getRequiredDocumentName(documentType) {
    switch (documentType) {
      case 'REGISTRATION_CERTIFICATE':
        return 'Meldebescheinigung'
      case 'RESIDENCE_PERMIT':
        return 'Aufenthaltstitel'
      case 'INVOICE':
        return 'Rechnung'
      case 'PAYROLL':
        return 'Gehaltsabrechnung'
      case 'PERSONAL_ID':
        return 'Ausweis'
      case 'PASSPORT':
        return 'Reisepass'
      default:
        return 'Unbekannt'
    }
  }

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.value)
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Zusätzliche Dokumente</title>
        <meta name='description' content='KREDU - zusätzliche Dokumente' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='h-100 container pt-lg-3'>
        <div
          size='12'
          lg='11'
          xl='8'
          xxl='6'
          className={`d-md-inline-block  additional-documents-screen px-md-3 px-2 py-3 py-md-0 col-12 col-lg-11 col-xl-8 col-xxl-6 ${classes.registrationFormBox2} ms-lg-3`}
        >
          <h3 className='mb-md-3 fw-bold'>
            Zusätzliche Unterlagen erforderlich
          </h3>
          <p className='pt-4 pt-md-0 ps-0 mb-4 fw-normal'>
            Wir haben deine Anfrage bearbeitet und benötigen zusätzliche
            Unterlagen von dir.
          </p>
          <ul>
            {requiredDocuments.map((documentType) => (
              <li key={documentType} className='mb-2'>
                {getRequiredDocumentName(documentType)}
              </li>
            ))}
          </ul>
          <form
            onSubmit={handleUploadRequiredDocument}
            className='mb-3 additional-documents'
          >
            {/* <label className='mb-2 font-blue fw-bold'>
            Upload
            </label> */}
            <br />
            <MDBSelect
              id='documentType'
              required              
              label='Dokument auswählen'
              data={requiredDocuments.map(doc => ({ value: doc, text: getRequiredDocumentName(doc) }))}
              onValueChange={handleDocumentTypeChange}
              validation              
              className={`${classes.documentSelect} mb-4 p-0`}
            />
            <input
              type='file'
              onChange={handleFileInputChange}
              accept='image/*, .pdf, .jpg, .png, .tiff'
              capture='environment'
              className='font-blue'
              ref={fileInputRef}
            ></input>
            <p className='text-muted fw-normal my-3'>
              Es können nur PDF-, JPG-, PNG- und TIFF-Dateien hochgeladen
              werden.
            </p>
            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              type='submit'
              disabled={isButtonDisabled}
              className={`${classes.btnYellow} btn-yellow mb-4 mt-auto mt-md-3 waves-effect`}
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Hochladen
            </MDBBtn>
          </form>

          {Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0 && (
            <div>
              <h4 className='fw-bold mb-3'>Hochgeladene Dokumente</h4>
              {isLoading && (
                <div className='text-center'>
                  <MDBSpinner
                    size='md'
                    tag='div'
                    className={`${classes.spinner} mt-5 mb-3`}
                  />
                </div>
              )}
              {!isLoading && (
                <>
                  <DocumentsList documents={uploadedDocuments} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default AdditionalDocumentsUpload
