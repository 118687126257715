import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'

import classes from './Error404Page.module.css'

import { Link } from 'react-router-dom'

import errorImg from '../../assets/img/error-img.svg'

function Error404Page() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Error 404</title>
        <meta name='description' content='KREDU - Error 404' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='text-center container'>
        <div className='text-center row'>
          <h2 className={`fw-bold mb-5 ${classes.marginTop}`}>
            oh nein, die Seite kann nicht gefunden werden
          </h2>
          <div className={` ${classes.errorBox} mx-auto mb-5 col`}>
            <img src={errorImg} alt='Error' className='img-fluid mb-4'></img>
            <h3 className='fw-bold my-3'>404 Fehler</h3>
            <p>
              {' '}
              Die von Ihnen angeforderte Seite konnte nicht gefunden werden.
              Bitte überprüfen Sie die eingegebene URL und versuchen Sie es
              erneut.
            </p>
            <Link className={classes.link} to='/'>
              Startseite
            </Link>
            <Link className={classes.link} to='/faq'>
              Faq
            </Link>
            <Link className={classes.link} to='/kontakt'>
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Error404Page
