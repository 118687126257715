import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { MDBBtn, MDBSpinner, useClipboard } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet-async'
import bankVerificationService from '../../services/bankVerificationService'

import FormError from '../../components/FormError'

import classes from './RegistrationBankVerification.module.css'

function RegistrationBankVerification() {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const handleButtonClick = async () => {
    setIsLoading(true)
    setIsError(false)

    try {
      const paymentUrl =
        await bankVerificationService.initializeBankVerification(user.token)
      setIsLoading(false)
      window.location.replace(paymentUrl)
    } catch (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - 10-Cent-Verifizierung</title>
        <meta name='description' content='KREDU - 10 Cent Verifizierung' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='h-100 container'>
        <div className={classes.registrationFormBox}>
          <h2 className='mb-3'>10-Cent-Verifizierung</h2>
          <p>
            Um deine Identität weiter zu verifizieren, bittet KREDU dich, hier
            eine 0,10 € Überweisung von deinem deutschen Konto durchzuführen.
          </p>
          <p>
            Klicke einfach auf den nachfolgenden Button und überweise online mit
            Sofortüberweisung (Klarna) 10 Cent. Schnell, sicher und günstig.
          </p>
          <b>Wir akzeptieren nur deutsche IBAN-Nummern.</b>
          <MDBBtn
            type='submit'
            onClick={handleButtonClick}
            className={`${classes.btnSecondaryBlue} btn mt-3 mb-4`}
          >
            {isLoading && (
              <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
            )}
            Weiter zu Sofortüberweisung (Klarna)
          </MDBBtn>
          {isError && <FormError />}
          <p className='mt-2'>
            Die Verifizierung dauert nur wenige Augenblicke. Fairplay und
            Sicherheit für dich und für KREDU.
          </p>
          <h3 className='fw-normal mb-3'>Kein Onlinebanking?</h3>
          <p>
            Du kannst auch 10 Cent auf das nachfolgende Konto überweisen. Wir akzeptieren nur deutsche IBAN-Nummern. Diese
            Verifizierung dauert 1-2 Werktage. KREDU informiert dich per E-Mail,
            sobald die Verifizierung abgeschlossen ist.
          </p>
          <p>Kontoinhaber: KREDU GmbH</p>
          <p>Bank: Berliner Volksbank</p>
          <p>IBAN: DE36 1009 0000 2726 1900 14</p>
          <p>BIC: BEVODEBB</p>
          <p>
            Verwendungszweck: deine E-Mail-Adresse (Schreibe statt dem @ einfach
            „-„)
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationBankVerification
