import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'

import inProcess from '../../assets/img/in-process-icon.svg'

function OrderPending() {
  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Wir prüfen deine Anfrage</title>
          <meta name="description" content="KREDU - Wir prüfen deine Anfrage" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='vh-100 pt-lg-5 container'>
        <div className='container-heigh row'>
          <div className='text-center col-12 col-sm-10 col-md-6 mx-auto'>
            <div className='text-center box-width'>
              <img src={inProcess} alt='inProcess' className='my-5'></img>
              <h2 className='fw-bold mb-4'>
                Wir prüfen deine
                <br /> Anfrage
              </h2>
              <p className='px-xl-5 px-sm-3'>
              Wir haben deine Anfrage erhalten und brauchen ein wenig Zeit, um deine Anmeldung zu bearbeiten. Sobald wir fertig sind, werden wir dich per E-Mail informieren.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default OrderPending
