import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ordersService from '../services/orderService'

const initialState = {
  orders: [],
  orderDetails: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  isNextPageLoading: false,
  isNextPageLoadingSuccess: false,
  isNextPageLoadingError: false,
  message: '',
}

export const loadInitialOrders = createAsyncThunk(
  'orders/loadInitialOrders',
  async ({ itemsPerPage }, thunkAPI) => {
    try {
      const user = thunkAPI.getState().auth.user
      if (user && user.userId) {
        return await ordersService.getOrders(user.token, itemsPerPage, 1)
      }
    } catch (error) {
      let errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const loadNextPageWithOrders = createAsyncThunk(
  'orders/loadNextPageWithOrders',
  async ({ itemsPerPage, pageNumber }, thunkAPI) => {
    try {
      const user = thunkAPI.getState().auth.user
      if (user && user.userId) {
        return await ordersService.getOrders(
          user.token,
          itemsPerPage,
          pageNumber
        )
      }
    } catch (error) {
      let errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const loadOrderDetails = createAsyncThunk(
  'orders/loadOrderDetails',
  async (orderId , thunkAPI) => {
    try {      
      const user = thunkAPI.getState().auth.user
      if (user && user.userId) {
        return await ordersService.getOrderDetails(user.token, orderId)
      }
    } catch (error) {
      let errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {

   },
  extraReducers: (builder) => {
    builder
      .addCase(loadInitialOrders.pending, (state) => {
        state.orders = []         
        state.isError = false
        state.isSuccess = false
        state.message = ''      
        state.isLoading = true
      })
      .addCase(loadInitialOrders.fulfilled, (state, action) => {
        state.orders = action.payload
        state.isError = false
        state.isSuccess = true
        state.isLoading = false             
      })
      .addCase(loadInitialOrders.rejected, (state, action) => {        
        state.isError = true
        state.message = action.payload
        state.isLoading = false        
      })
      .addCase(loadNextPageWithOrders.pending, (state) => {
        state.isNextPageLoading = true
      })
      .addCase(loadNextPageWithOrders.fulfilled, (state, action) => {
        state.orders = [...state.orders, ...action.payload]
        state.isNextPageLoading = false
        state.isNextPageLoadingSuccess = true
      })
      .addCase(loadNextPageWithOrders.rejected, (state, action) => {
        state.isNextPageLoading = false
        state.isNextPageLoadingError = true
        state.message = action.payload
      })
      .addCase(loadOrderDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loadOrderDetails.fulfilled, (state, action) => {
        state.orderDetails = action.payload
        state.isError = false          
        state.isSuccess = true
        state.isLoading = false
      })  
      .addCase(loadOrderDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, clearOrders } = ordersSlice.actions
export default ordersSlice.reducer
