import { MDBSpinner } from 'mdb-react-ui-kit';

import classes from './LoadingSpinner.module.css'

function LoadingSpinner() {
  return (
    <div className={`${classes.backdrop} d-flex justify-content-center align-items-center`}>
      <MDBSpinner role='status'  style={{ width: '3.5rem', height: '3.5rem' }}>
        <span className='visually-hidden'>Loading...</span>
      </MDBSpinner>
    </div>
  )
}

export default LoadingSpinner