import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/user'

const getUserData = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL, config)    
    return response.data
}

const registerUser = async (email, wantsNewsletter, paymentMethod) => {
    const response = await axios.post(API_URL, 
        { 
            email: email,
            wantsNewsletter: wantsNewsletter,
            paymentMethod: paymentMethod
        }
    )     
    return response
}

const confirmRegisteredEmail = async (token, userId) => {       
    const response = await axios.post(API_URL + '/confirm-registered-email', {         
        token: token,
        userId: userId
    })          
    return response.data
}

const getUserCartParameters = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL + '/cart-parameters', config)
    return response.data
}

const getUserOrderRegistrationState = async (token, paymentMethod, hasKredupayVoucher) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL + 
        `/order-state?paymentMethod=${paymentMethod}&hasKredupayVoucher=${hasKredupayVoucher ? 'true' : 'false'}`, 
        token != null ? config : null)
    return response.data
}

const requestMobilePhoneNumberUpdate = async (token, number) => {       
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/request-mobile-phone-number-update', {        
        mobilePhoneNumber: number
    }, config)          
    return response.data
}

const confirmMobilePhoneNumber = async (token, otp) => {       
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/confirm-mobile-phone-number-update', {           
        otp: otp
    }, config)          
    return response.data
}

const sendSmsOtp = async (token, mobilePhoneNumber) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/send-sms-otp', 
    {           
        mobilePhoneNumber: mobilePhoneNumber
    }, config)          
    return response.data
}
const updateMobilePhoneNumber = async (token, mobilePhoneNumber, otp) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/update-mobile-phone-number', 
    {    
        mobilePhoneNumber: mobilePhoneNumber,       
        otp: otp
    }, config)          
    return response.data
}

const registerPersonalData = async (token, paymentMethod, personalData) => {       
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/register-personal-data', {           
        paymentMethod: paymentMethod,        
        salutation: personalData.salutation,
        firstName: personalData.firstName,
        lastName: personalData.lastName,
        birthdate: personalData.birthdate,
        streetName: personalData.streetName,
        houseNumber: personalData.houseNumber,
        postalCode: personalData.postalCode,
        city: personalData.city     
    }, config)             
    return response.data
}

const registerAdditionalPersonalData = async (token, paymentMethod, personalData) => {       
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL + '/register-additional-personal-data', {           
        paymentMethod: paymentMethod,        
        nationality: personalData.nationality,
        occupation: personalData.occupation,
        netIncome: personalData.netIncome
    }, config)          
    return response.data
}

const hasActiveLegacyMastercard = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL + '/has-active-legacy-mastercard', config)    
    return response.data

}

const userService = {
    getUserData,
    registerUser,
    confirmRegisteredEmail,
    requestMobilePhoneNumberUpdate,
    confirmMobilePhoneNumber,
    getUserCartParameters,
    getUserOrderRegistrationState,
    registerPersonalData,
    registerAdditionalPersonalData,
    hasActiveLegacyMastercard,
    sendSmsOtp,
    updateMobilePhoneNumber
}

export default userService

