import { useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { MDBBtn } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../data/constants'
import paymentService from '../../services/paymentService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlarmExclamation } from '@fortawesome/pro-light-svg-icons'

function PaymentExpired() {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!document.referrer.startsWith('https://www.sofort.com')) {
      navigate('/')
    }
    paymentService.updateLastPaymentStatus(user.token, 'EXPIRED')
  }, [])

  const handleButtonClick = () => {
    navigate(ROUTES.CART)
  }

  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Zahlung abgelaufen</title>
          <meta name="description" content="KREDU - Zahlung abgelaufen" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='text-center vh-100 pt-lg-5 container'>
        <div className='container-heigh row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <FontAwesomeIcon
                icon={faAlarmExclamation}
                fontSize='55px'
                className='mb-4'
              />
              <h2 className='mb-3 fw-bold'>Zahlung abgelaufen</h2>
              <p className='pt-4 pt-md-0 text-center ps-0 mb-4 fw-normal registration-box-info'>
                Leider ist dein Zahlungsfenster abgelaufen. Wenn du es noch
                einmal versuchen oder deine Bestellung ändern möchtest, klicke
                auf den Button unten, um zurück zum Warenkorb zu gelangen.
              </p>

              <MDBBtn
                type='button'
                onClick={handleButtonClick}
                className='btn-yellow mb-5 mb-md-0 mt-auto mt-md-3 waves-effect'
              >
                Zurück zum Warenkorb
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PaymentExpired
