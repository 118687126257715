import { useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import AllVendors from '../../components/AllVendors'
import AddProductToCart from '../../features/product/addProductToCart'
import classes from './Vendor.module.css'
import { loadVendorData } from '../../features/product/redux/productsSlice'
import LoadingSpinner from '../../components/LoadingSpinner'
import parse from 'html-react-parser'
import { reset } from '../../features/product/redux/productsSlice'
import CategoryLoadingError from '../../components/CategoryLoadingError'

function Vendor() {
  const routeParams = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  const { vendor, isLoading, isError, isSuccess } = useSelector(
    (state) => state.products
  )

  useEffect(() => {}, [vendor, isSuccess, isError])

  useEffect(() => {
    dispatch(reset())
    dispatch(loadVendorData(routeParams['slug']))
  }, [routeParams, dispatch])

  return (
    <Fragment>
      <Helmet>
        <title>{`KREDU - ${(vendor && vendor.name) ?? 'Anbieter'}`}</title>
        <meta name='description' content={`KREDU - ${(vendor && vendor.name) ?? 'Anbieter'}`} />
        <link
          rel='canonical'
          href={`${window.location.origin}${location.pathname}`}
        />
      </Helmet>
      <div className='bg-white h-100'>
        <div className='container mt-5 py-5 text-center'>
          {isSuccess && vendor && (
            <div className={`${classes.vendorInfo} row`}>
              <div className='text-lg-end text-center col-12 col-lg-4 d-none d-lg-flex align-items-center order-1 order-lg-1'>
                <img
                  src={`${process.env.PUBLIC_URL}/img/vendors/${routeParams['slug']}.svg`}
                  alt={routeParams['slug']}
                  className={`${classes.vendorLogo} me-lg-4 img-fluid`}
                />
              </div>
              <div className='text-start mt-3 mt-lg-5 mb-md-5 mb-3 col-12 col-lg-7 order-3 order-lg-2'>
                <h2 className={`${classes.vendorName} fw-bold`}>
                  {vendor.name}
                </h2>
                <div className='mt-3'>{parse(vendor.description)}</div>
              </div>
              <div className='row cardsSection justify-content-center pt-0 order-2 order-lg-3'>
                <h2
                  className={`${classes.vendorName} fw-bold mb-3 d-blobk d-lg-none`}
                >
                  {vendor.name}
                </h2>
                {vendor.products.map((product) => {
                  return (
                    <div key={product.id} className='col px-2'>
                      <AddProductToCart
                        productId={product.id}
                        slug={product.slug}
                        cartProductId={product.id}
                        isInStock={product.isInStock}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/img/products/${product.slug}.svg`}
                          alt='voucher'
                          className='img-fluid'
                        />
                      </AddProductToCart>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {isLoading && (
            <div className={classes.loading}>
              <LoadingSpinner />
            </div>
          )}
          {isError && !isLoading && <CategoryLoadingError />}
          <div>
            <h2 className='fw-bold mt-3 mt-lg-5 mb-4'>Alle Gutscheine</h2>
            <AllVendors />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Vendor
