import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/invoices'

const downloadInvoice = async (token, invoiceId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }, 
        responseType: 'blob'
    }    
    const response = await axios.get(API_URL + `/download/${invoiceId}`, 
        token != null ? config : null)
    
    return response.data
}

const invoiceService = {
    downloadInvoice
}

export default invoiceService