import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,   
  MDBCollapse,
  MDBBtn,
} from 'mdb-react-ui-kit'
import NavigationIdentity from './NavigationIdentity'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'

import classes from './CartNavigation.module.css'

import kreduLogo from '../assets/img/Kredu-logo.svg'

import { ROUTES } from '../data/constants'

function CartNavigation() {
  const [showNav, setShowNav] = useState(false)
  const [cartItemsTotalQty, setCartItemsTotalQuantity] = useState(0)  

  const { cartItems } = useSelector((state) => state.cart)

  useEffect(() => {
    const totalCartItemsQuantity = cartItems.reduce(
      (partialSum, x) => partialSum + x.quantity,
      0
    )
    setCartItemsTotalQuantity(totalCartItemsQuantity)
  }, [cartItems])  

  const handleNavbarItemClick = (e) => {
    setShowNav(false)
  }
  return (
      <div className='px-0 container-fluid'>
      <MDBNavbar
        expand='xl'
        className={`${classes.navbarCart}  fixed-top py-3`}
      >
        
          <Link className={classes.kreduLogo} to='/' onClick={handleNavbarItemClick}>
            <img src={kreduLogo} alt='KREDU'></img>
          </Link>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
            className='me-md-3 me-1'
          >
            <FontAwesomeIcon icon={faBars} fontSize='24px' className={classes.fontBlue} />
          </MDBNavbarToggler>
          <MDBCollapse navbar open={showNav}>
          <MDBNavbarNav className='ms-auto w-auto'>
            <MDBNavbarItem>
              <Link
                aria-current='page'
                to={ROUTES.ALL_PRODUCTS}
                className='me-xl-3 nav-link'
                onClick={handleNavbarItemClick}
              >
                Alle Gutscheine
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link
              to='/#soFunktioniert'
                aria-current='page'
                className='me-xl-3 nav-link'
                onClick={handleNavbarItemClick}
              >
                So funktioniert’s
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to={ROUTES.FAQ} className='me-xl-3 nav-link' onClick={handleNavbarItemClick}>
                FAQ
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to={ROUTES.CONTACT} className='nav-link me-xl-3' onClick={handleNavbarItemClick}>
                Kontakt
              </Link>
            </MDBNavbarItem>
            <NavigationIdentity />
            <Link to={ROUTES.ALL_PRODUCTS} onClick={handleNavbarItemClick}>
              <MDBBtn
                className={`${classes.btnYellow} ms-xl-3 me-xl-5 mb-3 mt-4 my-xl-0`}
              >
                Jetzt kaufen
              </MDBBtn>
            </Link>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      </div>
  )
}

export default CartNavigation