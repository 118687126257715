import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import userService from '../services/userService'
import LoadingSpinner from '../components/LoadingSpinner'
import { ROUTES } from '../data/constants'
import { setFinishedSteps } from '../features/registration/redux/registrationSlice'

import classes from './RegistrationAndOrderGuard.module.css'

const RegistrationAndOrderGuard = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { user } = useSelector((state) => state.auth)
    const { totalAmountWithoutServiceFee, paymentMethod, isCartSubmitted, cartItems } = useSelector((state) => state.cart)

    const location = useLocation()   
    const navigate = useNavigate()      
    const dispatch = useDispatch() 

    const processOrderState = (orderState) => {            
        const path = location.pathname
        let route = ''               
       
        switch(orderState) {           
            case 'EMAIL':  
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL                 
                if(path === ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT 
                || path === ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL_CONFIRMATION) {
                    navigate(path + location.search)
                } else if(path !== route) {
                    navigate(route)
                }
                break
            case 'MOBILE_PHONE_NUMBER':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER
                if(path === ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_OTP_CONFIRMATION) { 
                    navigate(path)
                } else if(path !== route) {
                    navigate(route)
                }
                break
            case 'BANK_VERIFICATION':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_BANK_VERIFICATION
                if(path !== route) navigate(route)
                break
            case 'BANK_VERIFICATION_PENDING':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_BANK_VERIFICATION_PENDING
                if(path !== route) navigate(route)
                break
            case 'PERSONAL_DATA':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_PERSONAL_DATA
                if(path !== route) navigate(route)
                break
            case 'USER_DECLINED':
                route = ROUTES.USER_DECLINED
                if(path !== route) navigate(route)
                break
            case 'BNPL_ORDER_DECLINED':
                route = ROUTES.BNPL_ORDER_DECLINED
                if(path !== route) navigate(route)
                break
            case 'UNVERIFIED_IDENTITY':
                route = ROUTES.UNVERIFIED_IDENTITY
                if(path !== route) navigate(route)
                break
            case 'PENDING':
                route = ROUTES.ORDER_PENDING
                if(path !== route) navigate(route)
                break
            case 'ADDITIONAL_PERSONAL_DATA':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA
                if(path !== route) navigate(route)
                break
            case 'ADDITIONAL_DOCUMENTS_NEEDED':            
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_ADDITIONAL_DOCUMENTS                
                if(path !== route) navigate(route)
                break
            case 'IDENTIFICATION':
                route = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_IDENTIFICATION
                if(path !== route) navigate(route)
                break;           
            case 'CHECKOUT':
                route = ROUTES.CHECKOUT
                if(path !== route) navigate(route)                   
                break
            case 'SUCCESS':
                route = ROUTES.ORDER_SUCCESSFUL
                if(path !== route) navigate(route)
                break;                
            default:
                throw new Error('Unknown order registration state.') 
        }
    }     
    
    useEffect(() => {  
        if(!isCartSubmitted || totalAmountWithoutServiceFee === 0) {             
            if(location.pathname !== ROUTES.CART) navigate(ROUTES.CART)                           
        }
        
        if(user === null) {                
            if(isCartSubmitted) {
                //When new user with a submitted cart that wants to go to paths other than cart or email registration pages
                const registrationEmailPath = ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL
                if(location.pathname !== registrationEmailPath && 
                    location.pathname !== ROUTES.CART &&
                    location.pathname !== ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT &&
                    location.pathname !== ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL_CONFIRMATION) {                              
                    navigate(registrationEmailPath)                    
                }                
            } else {
                //When new user with unsubmitted cart
                if(location.pathname !== ROUTES.CART) navigate(ROUTES.CART)                
            }                       
        } else {            
            let hasKredupayVoucherAbove100 = false
            try {                
                hasKredupayVoucherAbove100 = cartItems.some(item => item.name !== null && item.name.toLowerCase().includes('kredupay') && item.price >= 100)    
            } catch { }           
            userService.getUserOrderRegistrationState(user ? user.token : null, 
                paymentMethod, hasKredupayVoucherAbove100)
                .then(response => {          
                    if(location.pathname === ROUTES.CART) {
                        //A registered user can always go to cart as long as its state is not in the checkout.                           
                        if(isCartSubmitted && response.currentOrderState === 'CHECKOUT') {       
                            navigate(ROUTES.CHECKOUT)
                        } else { 
                            navigate(ROUTES.CART)
                        }
                    } else {                      
                        processOrderState(response.currentOrderState)    
                    }               
                    dispatch(setFinishedSteps(response.finishedRegistrationSteps))                                 
                })
                .catch(error => {                              
                    navigate(ROUTES.ERROR_500)     
                }
            )          
        }     
        setIsLoading(false)             
    }, [user, location.pathname, location.search, navigate])   
        
        return (isLoading ? 
            <div className={`mx-auto ${classes.spinnerContainer}`}>
                <LoadingSpinner />
            </div> 
        : 
            <Outlet />
        )
}

export default RegistrationAndOrderGuard