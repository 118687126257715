import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import uploadFile from '../../assets/img/upload-file-icon.svg'
import errorFile from '../../assets/img/upload-file-error.svg'

import classes from './RegistrationAdditionalDocumentsMessage.module.css'

function RegistrationAdditionalDocumentsMessage() {
  const [searchParams] = useSearchParams()

  const isEuropeanUnion = searchParams.get('eu')

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - zusätzliche Dokumente</title>
        <meta name='description' content='KREDU - zusätzliche Dokumente' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='h-100 container pt-lg-3'>
        <div
          size='12'
          lg='11'
          xl='8'
          xxl='6'
          className={`d-md-inline-block button-alligment additional-documents-screen px-md-3 px-1 py-3 py-md-0 col-12 col-lg-11 col-xl-8 col-xxl-6 ${classes.registrationFormBox2} ms-lg-3`}
        >
          <h3 className='mb-md-3 fw-bold'>
            Zusätzliche Unterlagen erforderlich
          </h3>
          <p className='pt-4 pt-md-0 ps-0 mb-5 fw-normal'>
            Wir haben deine Anfrage bearbeitet und benötigen zusätzliche
            Unterlagen von dir.
          </p>
          <p className='fw-normal mb-4'>
            Bitte sende alle unten aufgelistete Dokumente an unsere
            E-Mail-Adresse:{' '}
            <a href='mailto:support@kredu.de' className='fw-bold'>
              support@kredu.de
            </a>
          </p>
          <div class='input-group mb-3 additional-documents'>
            <label class='input-group-text font-blue fw-bold'>
              <img src={uploadFile} alt='file' className='me-3'></img>
              <img src={errorFile} alt='file' className='me-3 d-none'></img>
              {isEuropeanUnion === 'true'
                ? 'Meldebescheinigung'
                : 'Aufenthaltstitel'}
            </label>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationAdditionalDocumentsMessage
