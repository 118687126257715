import { configureStore } from '@reduxjs/toolkit'
import cartReducer from '../features/cart/redux/cartSlice'
import authReducer from '../features/auth/authSlice'
import registrationReducer from '../features/registration/redux/registrationSlice'
import productsReducer from '../features/product/redux/productsSlice'
import ordersReducer from './ordersSlice'

const store = configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    registration: registrationReducer,
    products: productsReducer,
    orders: ordersReducer,    
  },
})

export default store
