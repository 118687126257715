import { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signOut } from '../features/auth/authSlice'
import { MDBNavbarItem } from 'mdb-react-ui-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons'
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons'
import { faUser } from '@fortawesome/pro-light-svg-icons'  
import { ROUTES } from '../data/constants'

function NavigationIdentity({closeNavbarHandler}) {
    const { user } = useSelector((state) => state.auth)  

    const dispatch = useDispatch()    
    const navigate = useNavigate()

    useEffect(() => {
    }, [user, dispatch])

    const signOutLinkOnClickHandler = (e) => {
        dispatch(signOut())
        navigate('/')
        closeNavbarHandler()
    }

    const signInLinkOnClickHandler = (e) => {
      closeNavbarHandler()
    }

    return (
        <Fragment>
        {user !== null ? (
            <>
              <MDBNavbarItem>
                <Link to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS} className='me-xl-3 nav-link'>
                  <FontAwesomeIcon
                    icon={faUser}
                    fontSize='17px'
                    className='me-1 font-blue'
                  />
                  Mein Konto
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to='/' className='me-xl-3 nav-link' onClick={signOutLinkOnClickHandler}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    fontSize='17px'
                    className='me-1 font-blue'
                  />
                  Abmelden
                </Link>
              </MDBNavbarItem>
            </>
          ) : (
            <MDBNavbarItem>
              <Link to={ROUTES.SIGN_IN} className='me-xl-3 nav-link' onClick={signInLinkOnClickHandler}>
                <FontAwesomeIcon
                  icon={faRightToBracket}
                  fontSize='17px'
                  className='me-1 font-blue'
                />
                Anmelden
              </Link>
            </MDBNavbarItem>
          )}
        </Fragment>
    )
}

export default NavigationIdentity