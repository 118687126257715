import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MDBBtn, MDBSpinner, MDBCard } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet-async'

import userService from '../../../services/userService'

import classes from './assets/BankVerification1Cent.module.css'

import FormError from '../../../components/FormError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'

function BankVerification1Cent() {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bankVerificationCode, setBankVerificationCode] = useState('')

  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    userService
      .getUserData(user.token)
      .then((response) => {
        setBankVerificationCode(response.bankVerificationCode)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsError(true)
        setIsLoading(false)
      })
  }, [])

  const handleCopyContent = (content) => {
    navigator.clipboard.writeText(content)
  }

  return (
    <Fragment>
      <Helmet>
        <title>1-Cent-Verifizierung</title>
        <meta name='description' content='KREDU - 1 Cent Verifizierung' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='h-100 container'>
        <div className={classes.registrationFormBox}>
          <h2 className='mb-4'>1-Cent-Verifizierung</h2>
          <p>
            Um deine Identität zu verifizieren, überweise uns bitte 1 Cent von
            deinem deutschen Bankkonto mit dem Verwendungszweck{' '}
            <span className={classes.bold}>KD{bankVerificationCode}</span>. Sobald
            wir deine Überweisung bestätigt haben, erhältst du eine E-Mail mit
            dem Verifizierungsstatus und kannst deine Bestellung aufgeben. Wir
            akzeptieren ausschließlich deutsche IBAN-Nummern. Bitte überweise 1
            Cent auf folgendes Konto:
          </p>
          <MDBCard className='px-3 py-4 mt-5 me-sm-4'>
            <p>
              Kontoinhaber: KREDU GmbH
              <MDBBtn
                className={`${classes.btnLight} btn-light btn btn-sm ms-2 py-1 px-2`}
                onClick={() => handleCopyContent('KREDU GmbH')}
              >
                <FontAwesomeIcon icon={faCopy} size='lg' />
              </MDBBtn>
            </p>
            <p>
              IBAN: DE36 1009 0000 2726 1900 14
              <MDBBtn
                className={`${classes.btnLight} btn-light btn btn-sm ms-2 py-1 px-2`}
                onClick={() => handleCopyContent('DE36 1009 0000 2726 1900 14')}
              >
                <FontAwesomeIcon icon={faCopy} size='lg' />
              </MDBBtn>
            </p>
            <p>
              Verwendungszweck:{' '}
              <span className={classes.bold}>KD{bankVerificationCode}</span>
              <MDBBtn
                className={`${classes.btnLight} btn-light btn btn-sm ms-2 py-1 px-2`}
                onClick={() => handleCopyContent(bankVerificationCode)}
              >
                <FontAwesomeIcon icon={faCopy} size='lg' />
              </MDBBtn>
            </p>
            <p>
              BIC: BEVODEBB
              <MDBBtn
                className={`${classes.btnLight} btn-light btn btn-sm ms-2 py-1 px-2`}
                onClick={() => handleCopyContent('BEVODEBB')}
              >
                <FontAwesomeIcon icon={faCopy} size='lg' />
              </MDBBtn>
            </p>
            <p>
              Bank: Berliner Volksbank
              <MDBBtn
                className={`${classes.btnLight} btn-light btn btn-sm ms-2 py-1 px-2`}
                onClick={() => handleCopyContent('Berliner Volksbank')}
              >
                <FontAwesomeIcon icon={faCopy} size='lg' />
              </MDBBtn>
            </p>
          </MDBCard>
        </div>
      </div>
    </Fragment>
  )
}

export default BankVerification1Cent
