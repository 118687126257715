import classes from './FormErrors.module.css'
import { ERROR_MESSAGES } from '../data/constants'

function FormError({ errors }) {
    let i = 1        
    return (        
        <div className='alert error-pink mb-3 py-3 px-3 mx-auto'>            
            {Array.isArray(errors) && errors.length > 0 && errors.filter(x => x !== '').length > 0 ? ( 
                errors.length === 1 ? (
                    <div className={classes.errorItem}>{errors[0]}</div>
                ) : (
                    <ul>
                    {
                    errors.map(error => {
                        return <li key={i++} className={classes.errorItem}>error</li>
                    })
                    }
                    </ul>
                )
            ) : (
                <span>{ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE}</span>                
            )
            }            
        </div>
    )
}

export default FormError