import { useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import AllVendors from '../../components/AllVendors'
import AddProductToCart from '../../features/product/addProductToCart/index'
import ProductLoadingError from '../../components/ProductLoadingError'
import { loadProductDetails } from '../../features/product/redux/productsSlice'
import LoadingSpinner from '../../components/LoadingSpinner'
import parse from 'html-react-parser'
import { reset } from '../../features/product/redux/productsSlice'

import classes from './Product.module.css'

function Product() {
  const routeParams = useParams()
  const dispatch = useDispatch()
  const location = useLocation();

  const { productDetails, isLoading, isError, isSuccess } = useSelector(
    (state) => state.products
  )

  useEffect(() => {}, [productDetails, isSuccess, isError])

  useEffect(() => {
    dispatch(reset())
    dispatch(loadProductDetails(routeParams['slug']))
  }, [routeParams, dispatch])

  return (
    <Fragment>
      <Helmet>
        <title>{`KREDU - ${(productDetails && productDetails.name) ?? 'Produkt'}`}</title>
        <meta name='description' content={`KREDU - ${(productDetails && productDetails.name) ?? 'Produkt'}`} />
        <link
          rel='canonical'
          href={`${window.location.origin}${location.pathname}`}
        />
      </Helmet>
      <div className='bg-white'>
        <div className='mt-5 py-5 text-center container'>
          <div className={`${classes.vendorInfo} pb-5 row`}>
            {isSuccess && productDetails && (
              <div className='col-12 col-lg-5 col-xl-4 text-lg-end text-center cards-section pt-2 mt-lg-5 px-sm-2 pb-2'>
                <AddProductToCart
                  productId={productDetails.id}
                  slug={productDetails.slug}
                  cartProductId={productDetails.id}
                  isInStock={productDetails.isInStock}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/products/${productDetails.slug}.svg`}
                    alt='voucher'
                    className='img-fluid'
                  />
                </AddProductToCart>
              </div>
            )}
            {isSuccess && productDetails && (
              <div className='text-start my-lg-5 col-12 col-lg-6 col-xl-7'>
                <h2 className='fw-bold'>{productDetails.name}</h2>
                <p className='mt-3'>{parse(productDetails.description)}</p>
              </div>
            )}
            {isSuccess && productDetails && productDetails.relatedProducts && (
              <div>
                <h2 className='fw-bold my-3'>Ähnliche Produkte</h2>
                <div className='row cards-section pt-0 justify-content-center'>
                  {productDetails.relatedProducts.map((product) => {
                    return (
                      <div key={product.id} className='col mb-2 mb-xl-0 px-2'>
                        <AddProductToCart
                          productId={product.id}
                          slug={product.slug}
                          cartProductId={product.id}
                          isInStock={product.isInStock}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/img/products/${product.slug}.svg`}
                            alt='voucher'
                            className='img-fluid'
                          />
                        </AddProductToCart>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {isLoading && (
              <div className={classes.loading}>
                <LoadingSpinner />
              </div>
            )}
            {isError && !isLoading && <ProductLoadingError />}
            <h2 className='fw-bold my-4'>Alle Gutscheine</h2>
            <AllVendors />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Product
