import { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { loadOrderDetails } from '../../../redux/ordersSlice'
import { MDBCard } from 'mdb-react-ui-kit'
import Invoices from './components/Invoices'
import NoInvoicesYet from './components/NoInvoicesYet'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DefaultReduxLoadingError from '../../../components/DefaultReduxLoadingError'
import CardCreated from './components/CardCreated'
import CardNotYetCreated from './components/CardNotYetCreated'
import KredupayCardExpired from './components/KredupayCardExpired'

import OrderNumber from './components/OrderNumber'

import classes from './assets/css/Order.module.css'

function DashboardOrder() {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { orderDetails, isLoading, isError } = useSelector(
    (state) => state.orders
  )

  useEffect(() => {
    dispatch(loadOrderDetails(id))
  }, [])

  if (isLoading) {
    return (
      <div
        className={`${classes.container} vh-100 pb-5 pt-5 pt-lg-3 mt-5 container`}
      >
        <div className={`mx-auto ${classes.spinnerContainer}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (isError) {
    return <DefaultReduxLoadingError />
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Gekaufte Artikel</title>
        <meta name='description' content='KREDU - Gekaufte Artikel' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div
        className={`${classes.bgGradient} p-0 px-xl-5 pt-xl-5 pb-xl-2 mt-md-5 mx-xl-auto container-fluid`}
      >
        <MDBCard
          className={`mx-xl-5 px-3 pt-lg-3 pb-0 ${classes.dashboardCard}`}
        >
          <OrderNumber orderId={id} />
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h4 className='fw-bold ms-sm-5 ms-xl-0'>Gekaufte Artikel</h4>
              <MDBCard className='mb-5 px-3 pt-4 pb-3 mx-xl-0 mx-sm-5 mx-1'>
                {orderDetails &&
                  orderDetails.orderItems.map((item) =>
                    orderDetails.vouchersReady === true ? (
                      item.vouchers.map((voucher) =>
                        item.productName.toLowerCase().includes('kredupay') ? (
                          <div className={`${classes.paymentBox} mb-3`}>
                            {voucher.daysLeft <= 0 ? (
                              <KredupayCardExpired 
                              key={voucher.serialNumber}
                              productName={item.productName}
                              price={item.price}
                              cvc={voucher.cvc}
                              last4Digits={voucher.last4Digits}
                              expiryMonth={voucher.expiryMonth}
                              />
                            ) : (
                              <CardCreated
                                key={voucher.serialNumber}
                                productName={item.productName}
                                price={item.price}
                                serialNumber={voucher.serialNumber}
                                last4Digits={voucher.last4Digits}
                                expiryMonth={voucher.expiryMonth}
                                cvc={voucher.cvc}
                                daysLeft={voucher.daysLeft}
                                cardImageBase64={voucher.cardImage}
                                cardBalance={voucher.cardBalance}
                              />
                            )}
                          </div>
                        ) : (
                          <div key={voucher.serialNumber}>
                            <div className='d-flex justify-content-between col'>
                              <span className=''>{item.productName}</span>
                              <span>
                                {item.price.toFixed(2).replace('.', ',')} €
                              </span>
                            </div>
                            <div
                              className={`${classes.paymentBox} mb-3 pb-1 col`}
                            >
                              <span className='text-muted s-text text-start'>
                                Seriennummer: {voucher.serialNumber}
                              </span>
                            </div>
                          </div>
                        )
                      )
                    ) : item.productName.toLowerCase().includes('kredupay') ? (
                      <CardNotYetCreated
                        key={item.productName}
                        productName={item.productName}
                        price={item.price}
                        quantity={item.quantity}
                      />
                    ) : (
                      <div>
                        <div className='d-flex justify-content-between col'>
                          <span className=''>{item.productName}</span>
                          <span>
                            {item.totalAmount.toFixed(2).replace('.', ',')} €
                          </span>
                        </div>
                        <div className='payment-box mb-3 pb-1 col'>
                          <span className='text-muted s-text'>
                            Anzahl: {item.quantity}
                          </span>
                        </div>
                      </div>
                    )
                  )}

                <div
                  className={`d-flex mb-3 pb-1 ${classes.paymentBox} justify-content-between col`}
                >
                  <span className=''>Servicegebühr</span>
                  <span>
                    {orderDetails != null
                      ? orderDetails.serviceFee.toFixed(2).replace('.', ',')
                      : ''}{' '}
                    €
                  </span>
                </div>

                <div className='d-flex justify-content-end mb-2 col'>
                  <span className={`${classes.small} align-self-center`}>
                    MwSt. (19%)
                  </span>
                  <span className={`ms-3`}>
                    {' '}
                    {orderDetails != null
                      ? orderDetails.vat.toFixed(2).replace('.', ',')
                      : ''}{' '}
                    €
                  </span>
                </div>
                <div className='d-flex justify-content-end align-items-center col'>
                  Gesamt
                  <span className='ms-3 me-1 fw-bold fs-3'>
                    {' '}
                    {orderDetails != null
                      ? orderDetails.totalAmount.toFixed(2).replace('.', ',')
                      : ''}{' '}
                  </span>
                  €
                </div>
              </MDBCard>
            </div>
            <div className='mb-5 col-12 col-lg-6'>
              <h4 className='fw-bold ms-sm-5 ms-xl-0'>Rechnungen</h4>
              {orderDetails &&
              orderDetails.invoices &&
              Array.isArray(orderDetails.invoices) &&
              orderDetails.invoices.length > 0 ? (
                <Invoices invoices={orderDetails.invoices} />
              ) : (
                <NoInvoicesYet />
              )}
            </div>
          </div>
        </MDBCard>
      </div>
    </Fragment>
  )
}

export default DashboardOrder
