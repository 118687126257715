import { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import userService from '../../../../services/userService'
import FormError from '../../../../components/FormError'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInputGroup,
  MDBInput,
  MDBSpinner,
} from 'mdb-react-ui-kit'

import classes from '../assets/css/PhoneNumberEditModal.module.css'

function PhoneNumberEditModal(props) {
  const [userData, setUserData] = useState('')
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [isNumberInputDisabled, setIsNumberInputDisabled] = useState(false)
  const [isOtpInputDisabled, setIsOtpInputDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSmsBeingSent, setIsSmsBeingSent] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const handleMobilePhoneNumberChange = (e) => {
    setMobilePhoneNumber(e.target.value.toString())
  }

  const handleOtpChange = (e) => {
    setOtp(e.target.value.toString())
  }

  useEffect(() => {
    userService.getUserData(user.token).then((data) => {
      setUserData(data)
    })
  }, [user.token])

  const resetState = () => {
    setMobilePhoneNumber('')
    setIsOtpSent(false)
    setOtp('')
    setIsNumberInputDisabled(false)
    setIsOtpInputDisabled(false)
    setIsLoading(false)
    setIsError(false)
    setErrorMessage('')
  }

  const handleModalClose = () => {
    resetState()
    props.onHide()
  }

  const handleSendOtpSms = (e, isFirstTime = true) => {
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    if(isFirstTime) {
      setIsLoading(true)
    } else {
      setIsSmsBeingSent(true)
    }


    userService
      .sendSmsOtp(user.token, mobilePhoneNumber)
      .then((response) => {
        if (response === 'NOT_GERMAN_NUMBER') {
          setIsError(true)
          setErrorMessage('Das ist keine gültige deutsche Handynummer.')
          setIsLoading(false)
          return
        } else if (response === 'NUMBER_ALREADY_EXISTS') {
          setIsError(true)
          setErrorMessage('Diese Mobilnummer ist bereits registriert.')
          setIsLoading(false)
          return
        } else if (response === 'SAME_NUMBER') {
          setIsError(true)
          setErrorMessage('Das ist deine aktuelle Handynummer.')
          setIsLoading(false)
          return
        }

        setIsOtpSent(true)
        setIsNumberInputDisabled(true)
        setIsLoading(false)
        setIsSmsBeingSent(false)
      })
      .catch((error) => {
        setIsError(true)
        if (error.response.status === 429) {
          setErrorMessage(
            'Du kannst nur fünf SMS in einer Minute schicken. Bitte warte eine Minute und versuche es erneut.'
          )
          setIsLoading(false)
          setIsSmsBeingSent(false)
        }
      })
  }

  const handleOtpConfirm = (e) => {
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    userService
      .updateMobilePhoneNumber(user.token, mobilePhoneNumber, otp)
      .then((response) => {
        localStorage.removeItem('is-otp-sent')
        setIsOtpInputDisabled(true)
        props.onHide()
        props.reload()
        setIsLoading(false)
      })
      .catch((error) => {
        setIsError(true)
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data === 'Invalid token.'
        ) {
          setErrorMessage('Der eingegebene Bestätigungscode ist ungültig.')
        }
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <MDBModal {...props} tabIndex='-1' staticBackdrop>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Änderung der Mobilnummer</MDBModalTitle>
              <MDBBtn
                className='btn-close'
                color='none'
                onClick={handleModalClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className={`${classes.modalBody} px-4`}>
              <p className=' mb-1 d-block font-black'>
                Deine aktuelle Mobilnummer:
              </p>
              {userData && userData.mobilePhoneNumber && (
                <p className=' text-muted d-block mb-3'>
                  +49 {userData.mobilePhoneNumber}
                </p>
              )}
              {!isOtpSent && (
                <form onSubmit={handleSendOtpSms} className={` w-100`}>
                  <p className=' mb-1 d-block font-black'>
                    Deine neue Mobilnummer:
                  </p>
                  {
                    <MDBInputGroup
                      noBorder
                      textBefore='+49'
                      className='d-flex mb-3 w-100'
                    >
                      <MDBInput
                        className={`form-control pe-1 rounded mb-0 `}
                        type='number'
                        placeholder='Handynummer'
                        required
                        disabled={isNumberInputDisabled}
                        pattern='^(?:.*[0-9]){11,}$'
                        onChange={handleMobilePhoneNumberChange}
                        value={mobilePhoneNumber}
                      />
                    </MDBInputGroup>
                  }
                  {isError && <FormError errors={[errorMessage]} />}

                  <MDBBtn
                    type='submit'
                    className={` btn bg-dark-blue mt-2 waves-effect`}
                  >
                    {isLoading && (
                      <MDBSpinner
                        size='sm'
                        role='status'
                        tag='span'
                        className='me-2 fst-normal'
                      />
                    )}
                    Weiter
                  </MDBBtn>
                </form>
              )}

              {isOtpSent && (
                <form onSubmit={handleOtpConfirm}>
                  <p className=' mb-1 d-block font-black'>
                    Deine neue Mobilnummer:
                  </p>
                  {
                    <MDBInputGroup
                      noBorder
                      textBefore='+49'
                      className='d-flex mb-3 w-100'
                    >
                      <MDBInput
                        className={`form-control pe-1 rounded mb-0 `}
                        type='number'
                        placeholder='Handynummer'
                        required
                        disabled={isNumberInputDisabled}
                        pattern='^(?:.*[0-9]){11,}$'
                        onChange={handleMobilePhoneNumberChange}
                        value={mobilePhoneNumber}
                      />
                    </MDBInputGroup>
                  }
                  <MDBInputGroup className='mb-3 w-100'>
                    <MDBInput
                      label='Bestätigungscode'
                      type='text'
                      pattern='\d*'
                      maxLength='6'
                      required
                      disabled={isOtpInputDisabled}
                      onChange={handleOtpChange}
                      value={otp}
                      className={`form-contol ps-2 pe-4 me-3`}
                    />
                  </MDBInputGroup>
                  {isError && <FormError errors={[errorMessage]} />}

                  <div>
                    <MDBBtn
                      type='submit'
                      className={` btn bg-dark-blue me-3 mt-2 waves-effect `}
                    >
                      {isLoading && (
                        <MDBSpinner
                          size='sm'
                          role='status'
                          tag='span'
                          className='me-2'
                        />
                      )}
                      Bestätigen
                    </MDBBtn>
                    <MDBBtn
                      onClick={(e) => handleSendOtpSms(e, false)}
                      className={` btn  mt-2 waves-effect `}
                    >
                      {isSmsBeingSent && (
                        <MDBSpinner
                          size='sm'
                          role='status'
                          tag='span'
                          className='me-2'
                        />
                      )}
                      SMS erneut senden
                    </MDBBtn>
                  </div>
                </form>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className='btn'
                color='secondary'
                onClick={handleModalClose}
              >
                Zurück
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </Fragment>
  )
}

export default PhoneNumberEditModal
