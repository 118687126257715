import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { MDBBtn, MDBSpinner, MDBScrollbar } from 'mdb-react-ui-kit'
import Order from './Order'

import classes from '../assets/css/OrdersList.module.css'

function OrdersList({ordersPerPage, loadMoreOrdersHandler}) {
  const { orders, isNextPageLoading } = useSelector((state) => state.orders)  

  const loadMoreOrders = (e) => {
    e.preventDefault()
    loadMoreOrdersHandler()
  }

  return (
    <Fragment>
      {/* <div className='d-flex mb-4 col-12'>
            <img src={calendar} alt='calendar'/>
            <MDBDatepicker
              format='dd.mm.yyyy'
              inputToggle
              labelText='Nach Datum filtern'
              value={datepickerInline}
              // setValue={setDatepickerInline}
              className={classes.purchaseDate}
            />
          </div> */}
      <MDBScrollbar style={{ position: 'relative', height: '500px' }}>
        <div className={`${classes.scrollBox} pe-3`}>
          {orders.map((order) => (
            <Order key={order.orderId} order={order} />
          ))}
        </div>
      </MDBScrollbar>      
      {isNextPageLoading && orders.length >= ordersPerPage && <div className='d-flex justify-content-center align-items-center mt-4'>
        <MDBSpinner grow className={`${classes.spinner}  me-3`} />  
        <MDBSpinner grow className={`${classes.spinner}  me-3`} />
        <MDBSpinner grow className={`${classes.spinner}  me-3`} />     
      </div>}
      {!isNextPageLoading && orders.length >= ordersPerPage && <MDBBtn
        id='loadMore'
        onClick={loadMoreOrders}
        rippleColor='light'
        color='tertiary'
        className={`${classes.btnBlue}  mt-4 mb-2 mx-auto`}
      >
        Mehr laden
      </MDBBtn>}
    </Fragment>
  )
}

export default OrdersList
