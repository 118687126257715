import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/orders'

const getOrders = async (token, itemsPerPage, pageNumber) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL + 
        `?itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`, 
        token != null ? config : null)
    return response.data
}

const getOrderDetails = async (token, orderId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL + 
        `/${orderId}`, 
        token != null ? config : null)
    return response.data
}

const orderService = {
    getOrders,
    getOrderDetails
}

export default orderService

