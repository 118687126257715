import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

const userFromStorage = localStorage.getItem('user')
const isMagicLinkSentFromStorage = localStorage.getItem('is-magic-link-sent')

const initialState = {
  user: userFromStorage ? JSON.parse(userFromStorage) : null,
  isMagicLinkSent: isMagicLinkSentFromStorage ?? false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
}

export const requestMagicLink = createAsyncThunk(
  'identity/requestMagicLink',
  async (email, thunkAPI) => {
    try {
      if (email === null) throw new Error('Invalid argument')
      await authService.requestMagicLink(email)
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.title ||
        error.toString()

      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const getToken = createAsyncThunk(
  'identity/getToken',
  async (params, thunkAPI) => {
    try {
      if (params.userId === null || params.token === null)
        throw new Error('Invalid arguments')
      const response = await authService.getToken(params.token, params.userId)
      return response
    } catch (error) {
      const errorMessage =
        (error.response && error.response.data) ||
        error.title ||
        error.toString()
      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.errorMessage = ''
    },
    resetIsMagicLinkSent: (state) => {
      state.isMagicLinkSent = false
      localStorage.removeItem('is-magic-link-sent')
    },
    signOut: (state) => {
      localStorage.removeItem('user')
      state.user = null
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestMagicLink.pending, (state) => {
        state.isLoading = true
      })
      .addCase(requestMagicLink.fulfilled, (state) => {
        state.isLoading = false
        state.isMagicLinkSent = true
        localStorage.setItem('is-magic-link-sent', true)
        state.hasLinkExpired = false
        state.isSuccess = true
      })
      .addCase(requestMagicLink.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.payload
      })
      .addCase(getToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.user = action.payload
        localStorage.setItem('user', JSON.stringify(action.payload))
        state.isLoading = false
        state.isMagicLinkSent = false
        localStorage.removeItem('is-magic-link-sent')
        state.isSuccess = true
      })
      .addCase(getToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.payload
      })
  },
})

export const { reset, resetIsMagicLinkSent, signOut, setUser } =
  authSlice.actions
export default authSlice.reducer