import axios from 'axios'
import qs from 'qs'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/products'

const loadProductData = async (productIds) => {    
    const response = await axios.get(API_URL, { 
        params: { productIds: productIds },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    }) 
    
    return response.data
}

const loadVendorData = async (slug) => {
    const response = await axios.get(API_URL + '/categories/' + slug)
    return response.data
}

const loadProductDetails = async (slug) => {
    const response = await axios.get(API_URL + '/' + slug + '/details')
    return response.data
}

const productService = {
    loadProductData,
    loadVendorData,
    loadProductDetails    
}

export default productService

