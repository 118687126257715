import { MDBCard, MDBBtn } from 'mdb-react-ui-kit'
import { useDispatch } from 'react-redux'
import { increaseCartItemQuantity, decreaseCartItemQuantity, removeCartItem } from '../../cart/redux/cartSlice'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTrashCan } from '@fortawesome/pro-light-svg-icons'

import classes from '../assets/css/CartItem.module.css'

function CartItem({productId, productName, quantity, price, slug, mode}) {
  const dispatch = useDispatch()

  function increaseQuantity() {
    const newQuantity = ++quantity
    dispatch(increaseCartItemQuantity({productId, newQuantity}))
  }

  function decreaseQuantity() {
    if(quantity > 1) {
      const newQuantity = --quantity
      dispatch(decreaseCartItemQuantity({productId, newQuantity}))
    }
  }

  function removeItem() {
    dispatch(removeCartItem(productId))
  }

  return (
    <MDBCard className={`${classes.shoppingCart} mb-3`}>
            <div className='row'>
              <div className='col-6 col-lg-5 col-xl-3'>
                <div className='row d-md-flex align-items-center'>
                  <div className='order-2 order-sm-1 col ps-2 ps-lg-3 pe-0'>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/products/${slug}.svg`}
                      alt='card'
                      className='px-0 pt-md-1 ms-1 ms-md-0'
                    ></img>
                  </div>
                  <div className='col order-1 order-sm-2'>
                    <p className='fw-bold text-normal mb-0 ms-2 ms-md-0 mt-1 mt-md-0'>
                      {productName}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-2 d-none d-xl-flex align-items-center justify-content-center pe-4 pe-lg-0 pt-2 pt-lg-0'>
              <p className='fw-bold text-lg-start text-end mb-1'>
                  <span>{price.toFixed(2).replace('.',',')}</span>&nbsp;€
                </p>
                
              </div>
              <div
                className='col-2 col-lg-3 d-flex align-items-center justify-content-lg-center justify-content-end'
              >               
                <form className='d-flex align-items-center justify-content-lg-center justify-content-end'>
                  {mode !== 'checkout' && <button  
                    type='button'                  
                    className={`${classes.addBtn} minus d-none d-md-block`}    
                    onClick={decreaseQuantity}                 
                  >
                    {/* <FontAwesomeIcon icon={faTrashCan} fontSize='16px' className='pt-1 pb-0' /> */}
                    <span className='mx-auto'>-</span>
                    
                  </button>
                  }
                  <input
                    type='number'
                    min='1'
                    step='1'
                    name='quantity'
                    className='ms-2'
                    plaintext='true'
                    readOnly                    
                    value={quantity}
                  />
                  {mode !== 'checkout' && <button  
                    type='button'                  
                    className={`${classes.addBtn} d-none d-md-block`} 
                    onClick={increaseQuantity}
                  >
                    <span className='mx-auto'>+</span> 
                  </button>
                  }
                </form>
              </div>              
              <div
                className='col-3 d-md-flex align-items-center justify-content-center pe-2 pe-lg-0 pt-2 pt-lg-0'
              >
                <p className='fw-bold text-lg-start text-end mb-1'>
                  <span>{(price*quantity).toFixed(2).replace('.',',')}</span>&nbsp;€
                </p>
                
                
                {mode !== 'checkout' && <button 
                  type='button'  
                  className={`${classes.addBtn} d-md-none d-block ms-auto ms-lg-0 mb-2`}
                  onClick={increaseQuantity}>
                  +
                </button>
                }               
                {mode !== 'checkout' && <button 
                  type='button'
                  className={`${classes.addBtn} d-md-none d-block ms-auto ms-lg-0 mb-2 `}
                  onClick={decreaseQuantity}>
                {/* <FontAwesomeIcon icon={faTrashCan} fontSize='15px' className=' pb-0' /> */}
                    <span>-</span>
                </button>
                }
              </div>
              {mode !== 'checkout' && <div className='col-1 text-end'>
                <MDBBtn className="btn-close mt-2 me-2 mt-md-3 me-md-3" color="none" aria-label="Close" onClick={removeItem} />
                </div>
              }
            </div>
          </MDBCard>
  )
}

export default CartItem