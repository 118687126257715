import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import unverified from '../../assets/img/rejected-icon.svg'

import classes from './UnverifiedIdentity.module.css'

function UnverifiedIdentity() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Leider konnten wir dich nicht identifizieren</title>
        <meta
          name='description'
          content='KREDU - Leider konnten wir dich nicht identifizieren'
        />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 pt-lg-5 text-center container'>
        <div className='container-heigh text-center row'>
          <div className='text-center col-12 col-sm-10 col-md-6 mx-auto'>
            <div className='text-center box-width'>
              <img src={unverified} alt='appRejected' className='my-5'></img>
              <h2 className='fw-bold mb-4'>
                Deine Identität konnte nicht bestätigt werden
              </h2>
              <p className='px-xl-5 px-sm-3'>
                Vielen Dank für dein Interesse an Kredu.
                <br />
                Leider können wir deine Bestellung nicht ausführen, da unser
                Partner WebID deine Identität nicht bestätigen konnte.
                <br />
                Wenn deine Identität aufgrund eines Fehlers nicht bestätigt
                werden konnte, wende dich bitte per E-Mail an unseren Support
                unter support@kredu.de, um die Identifizierung zu wiederholen.
                <br />
                Ansonsten kannst du versuchen, eine Bestellung unter 100€ per
                Sofortüberweisung aufzugeben.
              </p>
              <Link
                to='/warenkorb'
                className={`${classes.fontBlue} text-uppercase`}
              >
                zurück zum Warenkorb
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UnverifiedIdentity
