import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import DashboardPersonalInformationEdition from '../../components/DashboardPersonalInformationEdition'
import DashboardAdditionalInformationEdit from '../../components/DashboardAdditionalInformationEdit'

import classes from './DashboardPersonalInformationEdit.module.css'

function DashboardPersonalInformationEdit() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Persönliche Informationen</title>
        <meta
          name='description'
          content='KREDU - Persönliche Informationen'
        />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div
        className={`p-0 p-xl-5 my-5 mx-xl-auto container ${classes.dashboardInfoEdit}`}
      >
        <div className='row'>
          <DashboardPersonalInformationEdition />

          {/* <DashboardAdditionalInformationEdit /> */}
        </div>
      </div>
    </Fragment>
  )
}

export default DashboardPersonalInformationEdit
