import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import DefaultNavigation from '../components/DefaultNavigation'
import DefaultFooter from '../components/DefaultFooter'
import Content from '../components/Content'


function DefaultLayout({children}) {
  return (
    <Fragment>
        <DefaultNavigation />
          <Content>
            {children}
            <Outlet />
          </Content>
        <DefaultFooter />
    </Fragment>
  )
}

export default DefaultLayout