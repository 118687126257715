import { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBBtn,
} from 'mdb-react-ui-kit'
import NavigationIdentity from './NavigationIdentity'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'

import classes from './HomepageNavigation.module.css'

import kreduLogo from '../assets/img/Kredu-logo.svg'
import cartIcon from '../assets/img/cart-icon.svg'

import { ROUTES } from '../data/constants'
import { revertCartSubmit } from '../features/cart/redux/cartSlice'

function HomepageNavigation() {
  const [showNav, setShowNav] = useState(false)
  const [cartItemsTotalQty, setCartItemsTotalQuantity] = useState(0)
  const [colorChange, setColorChange] = useState(true)

  const { cartItems } = useSelector((state) => state.cart)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const totalCartItemsQuantity = cartItems.reduce(
      (partialSum, x) => partialSum + x.quantity,
      0
    )
    setCartItemsTotalQuantity(totalCartItemsQuantity)
  }, [cartItems])

  const changeColor = () => {
    if (window.scrollY <= 0) {
      setColorChange(true)
    } else {
      setColorChange(false)
    }
  }

  const handleNavbarItemClick = (e) => {
    setShowNav(false)
  }

  const handleGoToCartClick = (e) => {
    e.preventDefault()
    dispatch(revertCartSubmit())
    navigate(ROUTES.CART)
  }

  window.addEventListener('scroll', changeColor, true)

  return (
    <Fragment>
      <MDBNavbar
        expand='xl'
        className={
          colorChange
            ? `${classes.navbarHomepage} ${classes.navbarComp} fixed-top py-3`
            : `${classes.navbarHomepage} ${classes.navbarScrolled} fixed-top py-3`
        }
      >
        <div className='px-0 container-fluid'>
          <Link
            className={classes.kreduLogo}
            to='/'
            onClick={handleNavbarItemClick}
          >
            <img src={kreduLogo} alt='KREDU'></img>
          </Link>
          <Link
            to={ROUTES.CART}
            className={`${classes.badgeLink} d-xl-none nav-link ms-auto pe-1 pe-md-3`}
            onClick={handleNavbarItemClick}
          >
            <span
              className={`${classes.badge} ${
                cartItemsTotalQty > 0 ? '' : 'd-none'
              }`}
            >
              {cartItemsTotalQty}
            </span>
            <img
              src={cartIcon}
              width='23px'
              className={classes.cartImg}
              alt='warenkorb'
            ></img>
          </Link>

          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            data-target='#navbarNavHome'
            aria-controls='navbarNavHome'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
            className='me-md-3 me-1'
          >
            <FontAwesomeIcon
              icon={faBars}
              fontSize='24px'
              className={classes.fontBlue}
            />
          </MDBNavbarToggler>
          <MDBCollapse navbar open={showNav}>
            <MDBNavbarNav className='ms-auto w-auto'>
              <MDBNavbarItem>
                <Link
                  aria-current='page'
                  to={ROUTES.ALL_PRODUCTS}
                  className='me-xl-3 nav-link'
                  onClick={handleNavbarItemClick}
                >
                  Alle Gutscheine
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link
                  to='/#soFunktioniert'
                  aria-current='page'
                  className='me-xl-3 nav-link'
                  onClick={handleNavbarItemClick}
                >
                  So funktioniert’s
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link
                  to={ROUTES.FAQ}
                  className='me-xl-3 nav-link'
                  onClick={handleNavbarItemClick}
                >
                  FAQ
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link
                  to={ROUTES.CONTACT}
                  className='nav-link me-xl-3'
                  onClick={handleNavbarItemClick}
                >
                  Kontakt
                </Link>
              </MDBNavbarItem>
              <NavigationIdentity closeNavbarHandler={handleNavbarItemClick} />
              <Link to={ROUTES.ALL_PRODUCTS} onClick={handleNavbarItemClick}>
                <MDBBtn
                  className={`${classes.btnYellow} mx-xl-3 mb-3 mt-4 my-xl-0`}
                >
                  Jetzt kaufen
                </MDBBtn>
              </Link>

              <MDBNavbarItem>
                <Link
                  to={ROUTES.CART}
                  className={`${classes.badgeLink} ms-xl-3 me-xl-4 nav-link`}
                  onClick={handleGoToCartClick}
                >
                  <span
                    className={`${classes.badge} ${
                      cartItemsTotalQty > 0 ? '' : 'd-none'
                    }`}
                  >
                    {cartItemsTotalQty}
                  </span>
                  <img
                    src={cartIcon}
                    className={classes.cartImg}
                    alt='warenkorb'
                  ></img>
                </Link>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </div>
      </MDBNavbar>
    </Fragment>
  )
}

export default HomepageNavigation
