import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import appRejected from '../../assets/img/rejected-icon.svg'

import classes from './UserDeclined.module.css'

function UserDeclined() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Antrag abgelehnt</title>
        <meta name='description' content='KREDU - Antrag abgelehnt' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 pt-lg-5 text-center container'>
        <div className='container-heigh text-center row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <img src={appRejected} alt='appRejected' className='my-5'></img>
              <h2 className='fw-bold mb-4'>Dein Antrag wurde abgelehnt</h2>
              <p className='px-xl-5 px-sm-3'>
                Aufgrund unserer internen Richtlinien können wir dir leider
                kein Kundenkonto einrichten. Es kann sein, dass du ein doppeltes Konto in unserem System hast. 
                Bitte kontaktiere unseren Support für weitere Informationen.
              </p>
              <Link to='/' className={`${classes.fontBlue} text-uppercase`}>
                zurück zur Startseite
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UserDeclined
