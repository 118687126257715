import { useParams } from 'react-router-dom'
import { MDBBtn } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../data/constants'

import back from '../../../../assets/img/arrow-back.svg'


function OrderNumberMobile() {
  const { id } = useParams()

  return (
    <>
      <div className='d-block d-md-none bg-white'>
        <div className='d-flex align-items-center justify-content-between py-2'>
          <Link to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS}>
          <MDBBtn className='btn-arrow'>
            <img src={back} alt='back'></img>
          </MDBBtn>
          </Link>
          
          <h4 className='fw-bold mb-0 mx-auto pr-5'>Bestellung {id}</h4>
        </div>
      </div>
    </>
  )
}

export default OrderNumberMobile
