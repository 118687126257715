import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MDBBtn } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import success from '../../assets/img/success-icon.svg'
import { ROUTES } from '../../data/constants'
import { Helmet } from 'react-helmet-async'
import { clearCart } from '../../features/cart/redux/cartSlice'

function OrderSuccessful() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const isBnplOrderSuccessful = localStorage.getItem('bnpl-order-successful')
    if(!document.referrer.startsWith('https://www.sofort.com') && !isBnplOrderSuccessful) {
      navigate('/')
    } 
    localStorage.removeItem('bnpl-order-successful')
    dispatch(clearCart())
  }, [])

  const handleButtonClick = () => {
    navigate(ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS)  
  }

  return (
      <Fragment>
        <Helmet>
          <title>KREDU - Bestellung erfolgreich</title>
          <meta name="description" content="KREDU - Bestellung erfolgreich" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
        <div className='text-center vh-100 pt-lg-5 container'>
          <div className='container-heigh row'>
            <div
              className='text-center mx-auto col-12 col-sm-10 col-md-6'
            >
              <div className='text-center box-width'>
                <img
                  className='mb-2 mb-md-3 img-fluid'
                  src={success} alt='success'
                ></img>
                <h2 className='mb-3 fw-bold'>
                  Bestellung erfolgreich
                </h2>
                <p className='pt-4 pt-md-0 text-center ps-0 mb-4 fw-normal registration-box-info'>
                Vielen Dank für deine Bestellung. Wir werden sie so schnell wie möglich bearbeiten und dir
                deine Rechnung per E-Mail zusenden.
                </p>

                <MDBBtn
                  type='button'
                  onClick={handleButtonClick}
                  className='btn-yellow mb-5 mb-md-0 mt-auto mt-md-3 waves-effect'
                >
                  Zu deinem Konto
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  )
}

export default OrderSuccessful
