import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { MDBNavbar } from 'mdb-react-ui-kit'
import { ROUTES } from '../data/constants'

import classes from './RegistrationProgressMobile.module.css'

import arrowBack from '../assets/img/arrow-back.svg'
import cartIcon from '../assets/img/cart-icon.svg'

import { revertCartSubmit } from '../features/cart/redux/cartSlice'

function RegistrationProgressMobile() {
  const [cartItemsTotalQty, setCartItemsTotalQuantity] = useState(0)
  const { cartItems } = useSelector((state) => state.cart)
  const location = useLocation()  
  const navigate = useNavigate()
  const dispatch = useDispatch() 

  const handleGoBackClick = (e) => {
    e.preventDefault()
    navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER)
  }

  const getCurrentStepName = () => {
    const url = location.pathname

    if(url.endsWith(ROUTES.REGISTRATION_EMAIL) || 
      url.endsWith(ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT) ||
      url.endsWith(ROUTES.REGISTRATION_EMAIL_CONFIRMATION)) {
      return 'E-Mail Adresse'
    } else if(url.endsWith(ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER) ||
      url.endsWith(ROUTES.REGISTRATION_OTP_CONFIRMATION)) {
      return 'Handynummer'
    } else if(url.endsWith(ROUTES.REGISTRATION_PERSONAL_DATA)) {
      return 'Persönliche Daten'
    } else if(url.endsWith(ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA) || url.endsWith(ROUTES.REGISTRATION_ADDITIONAL_DOCUMENTS)) {
      return 'Zusätzliche Informationen'
    } else if(url.endsWith(ROUTES.REGISTRATION_BANK_VERIFICATION) || (url.endsWith(ROUTES.REGISTRATION_BANK_VERIFICATION_PENDING))) {
      return '1-Cent-Verifizierung'
    } else if(url.endsWith(ROUTES.REGISTRATION_IDENTIFICATION)) {
      return 'ID-Prüfung'
    }
  }

  const isAllowedToGoBack = () => {
    const url = location.pathname
    
    if(url.endsWith(ROUTES.REGISTRATION_OTP_CONFIRMATION)) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const totalCartItemsQuantity = cartItems.reduce(
      (partialSum, x) => partialSum + x.quantity,
      0
    )
    setCartItemsTotalQuantity(totalCartItemsQuantity)
  }, [cartItems])
  
  const handleGoToCartClick = (e) => {
    e.preventDefault()
    dispatch(revertCartSubmit())
    navigate(ROUTES.CART)
  }

  return (
      <div className='d-block d-md-none '>
        <MDBNavbar className={`${classes.navbar} d-flex align-items-center bg-white justify-content-between`}>
          <button onClick={handleGoBackClick} className={isAllowedToGoBack() ? `${classes.btnBack} py-0 align-self-center` : `${classes.btnBack} py-0 align-self-center invisible`}>
            <img src={arrowBack} alt='back'></img>
          </button>
          <p className='fw-bold mx-auto mb-0 ps-4 align-self-center'>
            {getCurrentStepName()}
          </p>
          <Link           
            to='/warenkorb'
            onClick={handleGoToCartClick}
            className={`${classes.badgeLink}  align-items-center d-flex`}
          >
            <span
              className={`${classes.badge} ${
                cartItemsTotalQty > 0 ? '' : 'd-none'
              }`}
            >
              {cartItemsTotalQty}
            </span>
            <img src={cartIcon} className={classes.cartImg} alt='shopping-cart'></img>
            <span className={`${classes.cartP} ms-1 me-2 font-black`}>Ändern</span>
          </Link>
        </MDBNavbar>
      </div>
  )
}

export default RegistrationProgressMobile