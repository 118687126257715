import { Fragment } from 'react'
import { MDBCard } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet-async'

import classes from './Contact.module.css'
import email from '../../assets/img/email-icon.svg'
import phone from '../../assets/img/phone-icon.svg'

function Contact() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Kontakt</title>
        <meta name='description' content='KREDU - Kontakt' />
      </Helmet>
      <section className={`${classes.contact} bg-grey py-5 vh-100`}>
        <div className='text-center container'>
          <MDBCard className={`${classes.card} mx-auto mb-3`}>
            <div className='card-body'>
              <p className='card-text'>
                Oder sende uns eine E-Mail. Deine Anfragen werden werktags im
                Regelfall binnen 24 Stunden beantwortet.
              </p>
              <img src={email} alt='email' />
              <a href='mailto:support@kredu.de' className='registrationLink'>
                <h3 className='mb-3 fw-bold font-black'>support@kredu.de</h3>
              </a>
            </div>
          </MDBCard>
        </div>
      </section>
    </Fragment>
  )
}

export default Contact
