import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import classes from './Error500Page.module.css'

import errorImg from '../../assets/img/error-img.svg'

function Error500Page() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Error 505</title>
        <meta name='description' content='KREDU - Error 505' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='text-center container'>
        <div className='text-center row'>
          <h2 className={`fw-bold mb-5 ${classes.marginTop}`}>
            oh nein, da ist etwas schief gelaufen
          </h2>
          <div className={` ${classes.errorBox} mx-auto mb-5 col`}>
            <img src={errorImg} alt='Error' className='img-fluid mb-4'></img>
            <h3 className='fw-bold my-3'>500 Fehler</h3>
            <p>
              Es tut uns leid. Ein interner Serverfehler ist aufgetreten. Wir
              arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es
              später noch einmal.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Error500Page
