import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

function Revocation() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Widerrufsbelehrung</title>
        <meta name='description' content='KREDU - Widerrufsbelehrung' />
      </Helmet>
      <div className='py-5 container'>
        <div class='row py-md-5'>
          <div class='col-lg-10 offset-lg-1 pb-5'>
            <h1 class='m-0 text-center my-5 fw-bold'>Widerrufsbelehrung</h1>
            <h3 class='mb-4 fw-bold'>Widerrufsrecht</h3>
            <p>
              Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne
              Angabe von Gründen mittels einer eindeutigen Erklärung widerrufen.
              Die Frist beginnt nach Erhalt dieser Belehrung auf einem
              dauerhaften Datenträger, jedoch nicht vor Vertragsschluss und auch
              nicht vor Erfüllung unserer Informationspflichten gemäß Artikel
              246 § 2 Absatz 1 in Verbindung mit Artikel 246b §.
            </p>
            <p class='blue-bg px-2 py-3 bg-blue'>
              1 Absatz 1 EGBGB. Zur Wahrung der Widerrufsfrist genügt die
              rechtzeitige Absendung des Widerrufs, wenn die Erklärung auf einem
              dauerhaften Datenträger (z.B. Brief, Telefax, E-Mail) erfolgt.
            </p>
            <p>Der Widerruf ist zu richten an:</p>
            <p class='bg-white px-2 py-3'>
              {' '}
              Kredu GmbH
              <br /> Bundesallee 220
              <br />
              D-10719 Berlin
              <br />
              E-Mail:
              <a href='mailto:support@kredu.de' className='registrationLink'>
                support@kredu.de
              </a>{' '}
            </p>
            <h3 class='my-4 fw-bold'>Widerrufsfolgen</h3>
            <p>
              Im Falle eines wirksamen Widerrufs sind die beiderseits
              empfangenen Leistungen zurück zu gewähren. Sie sind zur Zahlung
              von Wertersatz für die bis zum Widerruf erbrachte Dienstleistung
              verpflichtet, wenn Sie vor Abgabe Ihrer Vertragserklärung auf
              diese Rechtsfolge hingewiesen wurden und ausdrücklich zugestimmt
              haben, dass wir vor dem Ende der Widerrufsfrist mit der Ausführung
              der Gegenleistung beginnen. Besteht eine Verpflichtung zur Zahlung
              von Wertersatz, kann dies dazu führen, dass Sie die vertraglichen
              Zahlungsverpflichtungen für den Zeitraum bis zum Widerruf dennoch
              erfüllen müssen. Ihr Widerrufsrecht erlischt vorzeitig, wenn der
              Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch
              vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt
              haben.
              <br />
              <br />
              Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von
              30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der
              Absendung Ihrer Widerrufserklärung, für uns mit deren Empfang.
            </p>
            <h3 class='my-4 fw-bold'>Besonderer Hinweis:</h3>
            <p class='mb-5'>
              Das Widerrufsrecht für Dienstleistungen erlischt vorzeitig, wenn
              der Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch
              vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt
              haben. Beim Verkauf von digitaler Ware gilt eine außerordentliche
              Regelung zum vorzeitigen Erlöschen des Widerrufsrechts. Das
              Widerrufsrecht erlischt, wenn beide Seiten des Kaufvertrags
              vollständig erfüllt worden sind. Die Erfüllung seitens des Kunden
              ist mit der Kartennummernerstellung und/oder Aushändigung bzw.
              Übermittlung des Voucher-PIN mit dem Wertguthaben und die
              Erfüllung seitens des Händlers mit Lieferung der Ware gegeben.
              <br />
              <br />
              Die oben genannte Frist zum Rücktritt vom Kauf ist somit nur so
              lange gültig, wie die Ware noch nicht geliefert ist. Das
              Widerrufsrecht erlischt vorzeitig, wenn der Kunde die Ausführung
              der Dienstleistung vor Ende der Widerrufsfrist selbst veranlasst.
              Davon ist bei Inanspruchnahme einer bereits erstellten digitalen
              Kreditkartennummer und/oder Übermittlung eines Wertguthabens per
              PIN (Voucher) durch den Kunden auszugehen.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Revocation
