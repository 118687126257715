import { Fragment, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getToken, resetIsMagicLinkSent } from '../../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { MDBCol, MDBSpinner } from 'mdb-react-ui-kit'
import FormErrors from '../../components/FormError'
import { ROUTES } from '../../data/constants'

import classes from './Authentication.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

function Authentication() {
  const dispatch = useDispatch()  
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()  

  const { user, isLoading, isSuccess, isError, errorMessage, isMagicLinkSent } = useSelector(state => state.auth)  

  const token = searchParams.get('token')
  const userId = searchParams.get('userId')    

  useEffect(() => {    
    if(!isMagicLinkSent) navigate('/')    

    if(isSuccess) {      
      navigate(user && user.userId ? ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS : ROUTES.CART)      
    } else if(!isError) {
      dispatch(getToken({ token: token, userId: userId}))   
    }  
}, [token, userId, user, isSuccess, isError, isMagicLinkSent, navigate, dispatch])

  const handleLinkClicked = () => {
    dispatch(resetIsMagicLinkSent())
  }

  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Authentifizierung</title>
          <meta name="description" content="KREDU - Authentifizierung" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='vh-100 pt-lg-5 mt-xl-5 container'>
        <div className='container-heigh row'>
          <MDBCol
            size='12'
            sm='10'
            md='6'
            className='text-center offset-sm-1 offset-md-3'
          >
            <div className='text-center box-width'>
              <FontAwesomeIcon
                icon={faUser}
                fontSize='30px'
                className='me-3 font-blue'
              />
              <h3 className='mb-5 d-inline-block'>Authentifizierung</h3>                     
              <br />
              {isLoading && <MDBSpinner
                className='font-blue mb-4'
                style={{ width: '4rem', height: '4rem' }}
              >
                <span className='visually-hidden'>Loading...</span>
              </MDBSpinner>
              }
              {isError && errorMessage === 'Invalid token.' &&
              <div>
                <p className={`mx-auto fw-normal ${classes.pWidth}`}>
                  Dein Login-Link ist bereits abgelaufen. Er ist nur noch für 15
                  Minuten gültig. Geh zurück zur Login-Seite, um einen neuen Link
                  an deine E-Mail-Adresse zu senden.
                </p>
                <Link to={ROUTES.SIGN_IN} className={classes.registrationLink} onClick={handleLinkClicked}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    fontSize='20px'
                    className='me-2'
                  />
                  Zurück zum Login
                </Link>                
              </div>
              }
              {isError && errorMessage !== 'Invalid token.' && <FormErrors /> }
            </div>
          </MDBCol>
        </div>
      </div>
    </Fragment>
  )
}

export default Authentication
