import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import HomepageFooter from '../components/HomepageFooter'
import Content from '../components/Content'


function HomepageLayout( {children} ) {
  return (
    <Fragment>
          <Content>
            {children}
            <Outlet />
          </Content>
        <HomepageFooter />
    </Fragment>
  )
}

export default HomepageLayout