import { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AllVendors from '../../components/AllVendors'
import AddProductToCart from '../../features/product/addProductToCart/index'
import AllProductsLoadingError from '../../components/AllProductsLoadingError'
import {
  loadAllProducts,
  reset,
} from '../../features/product/redux/productsSlice'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Helmet } from 'react-helmet-async'

import classes from './AllProducts.module.css'

function AllProducts() {
  const dispatch = useDispatch()

  const { products, isLoading, isError, isSuccess } = useSelector(
    (state) => state.products
  )

  useEffect(() => {
    dispatch(reset())
    dispatch(loadAllProducts())
  }, [])

  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Alle Gutscheine</title>
          <meta name="description" content="KREDU - Alle Gutscheine" /> 
        </Helmet>
      <div className='bg-white h-100'>
        <div className='mt-4 pt-5 text-center container'>
          <h2 className='fw-bold mt-lg-5 mt-3 py-xl-5 pb-3'>
            Wähle deine gewünschte Guthabenkarten
          </h2>
          <div className='pb-5 row'>
            <AllVendors />
          </div>
          {isLoading && (
            <div className={classes.loading}>
              <LoadingSpinner />
            </div>
          )}
          {isSuccess && !isLoading && (
            <div className='row mb-xl-4 mt-2 mt-xl-4'>
              {products.map((product) => {
                return (
                  <div
                    key={product.id}
                    className='col-xxl-3 col-md-6 col mb-2 mb-xl-0'
                  >
                    <AddProductToCart
                      productId={product.id}
                      slug={product.slug}
                      cartProductId={product.id}
                      isInStock={product.isInStock}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/products/${product.slug}.svg`}
                        alt='voucher'
                        className='img-fluid'
                      />
                    </AddProductToCart>
                  </div>
                )
              })}
            </div>
          )}
          {isError && !isLoading && <AllProductsLoadingError />}
          <div className='text-center loading-button pt-lg-4 d-none d-lg-block'>
            {/* <MDBBtn className='text-capitalize btn-more my-lg-4 my-3 fw-bold font-black px-5 fs-6 py-2 bg-yellow'>
            Mehr Aufladecodes
          </MDBBtn> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AllProducts
