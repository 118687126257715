import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardNavigationDesktop from '../components/DashboardNavigationDesktop'
import Content from '../components/Content'
import DashboardMain from '../components/DashboardMain'

import DashboardNavigationMobile from '../components/DashboardNavigationMobile'

function DashboardLayout( {children} ) {
  return (
    <Fragment>
      <DashboardNavigationDesktop />
      <DashboardMain>
        <Content>
          {children}
          <Outlet />
        </Content>
        <DashboardNavigationMobile />
        </DashboardMain>
    </Fragment>
  )
}

export default DashboardLayout