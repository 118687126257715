import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import identificationService from '../../features/registration/services/identificationService'
import FormError from '../../components/FormError'
import { ROUTES } from '../../data/constants'
import { MDBBtn, MDBSpinner, MDBCheckbox } from 'mdb-react-ui-kit'

import idIcon from '../../assets/img/id-icon.svg'

import classes from './RegistrationIDVerification.module.css'

function RegistrationIDVerification() {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    identificationService
      .createWebIdAction(user.token)
      .then((response) => {
        if (response === 'ACCEPTED') {
          navigate(ROUTES.CHECKOUT)
        } else if (response === 'PENDING') {
          navigate(ROUTES.REGISTRATION + '/' + ROUTES.ORDER_PENDING)
        } else if (response === 'UNVERIFIED_IDENTITY') {
          navigate(ROUTES.REGISTRATION + '/' + ROUTES.UNVERIFIED_IDENTITY)
        } else {
          window.location.replace(response)
        }
      })
      .catch((error) => {
        setIsError(true)
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Identifizierung</title>
        <meta name='description' content='KREDU - Identifizierung' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 container'>
        <div className='row'>
          <div
            className={`text-center form-box text-md-start d-md-inline-block mx-auto px-1 px-sm-3 ${classes.registrationFormBox} ms-lg-3 me-lg-0 col-12 col-lg-11 col-xl-8 col-xxl-6`}
          >
            <img className='mb-2 mb-md-3 img-fluid' src={idIcon} alt='id'></img>
            <h2 className='mb-3 d-none d-md-block'>Identifizierung</h2>
            <p className='pt-4 pt-md-0 text-md-start text-center ps-0 fw-normal'>
              Um den Vorgang abzuschließen, müssen wir deine Identität
              überprüfen. Das kannst du bequem von zu Hause aus tun. Ein
              Mitarbeiter unseres zertifizierten Partners WebID Solutions GmbH
              führt dich per Videochat durch den Vorgang und überprüft deine
              Identität.
            </p>
            <p className='pt-3 pt-md-0 text-md-start text-center ps-0 mb-3 fw-normal'>
            Bitte halte dazu deinen Personalausweis oder Reisepass bereit.
            </p>
            <p className='pt-3 pt-md-0 text-md-start text-center ps-0 mb-3 fw-normal'>
            Hiermit bestätige ich, dass ich die AGB der WebID Solutions GmbH gelesen und verstanden habe.
            </p>
            <form onSubmit={handleFormSubmit}>
              <div className='d-flex mb-2 ps-2'>
                <MDBCheckbox
                  required
                  name='flexCheck'
                  value=''
                  id='newsletterAgreement'
                />
                <p className={`${classes.textSmall} fw-normal text-start mb-1`}>
                  Ich stimme den Hiermit bestätige ich, dass ich die{' '}
                  <a href='https://webid-solutions.de/agb/'>AGB</a> der WebID
                  Solutions GmbH gelesen und verstanden habe.{' '}
                  <a href='https://webid-solutions.de/agb/'>
                    Weitere Informationen
                  </a>
                </p>
              </div>

              {isError && <FormError />}
              <MDBBtn
                type='submit'
                className={`${classes.btnYellow} d-md-inline-block btn-yellow mt-2 waves-effect`}
              >
                {isLoading && (
                  <MDBSpinner
                    size='sm'
                    role='status'
                    tag='span'
                    className='me-2'
                  />
                )}
                Meine ID überprüfen
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationIDVerification
