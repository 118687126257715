import { useNavigate, useSearchParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from '../../features/auth/authSlice'
import { MDBSpinner } from 'mdb-react-ui-kit'
import FormErrors from '../../components/FormError'
import userService from '../../services/userService'
import { ROUTES } from '../../data/constants'

import classes from './RegistrationEmailConfirmation.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

function RegistrationEmailConfirmation() {  
    const [isError, setIsError] = useState(false)
    const [hasTokenExpired, setHasTokenExpired] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()  

    const dispatch = useDispatch()

    const token = searchParams.get('token')
    const userId = searchParams.get('userId')  
  
    useEffect(() => {
        setIsError(false)
        setHasTokenExpired(false)
        setIsLoading(true)

        const isEmailActivationLinkSent = localStorage.getItem('is-email-activation-link-sent')
        if(!isEmailActivationLinkSent) navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL) 

        userService.confirmRegisteredEmail(token, userId)
        .then(response => {            
            dispatch(setUser(response))
            localStorage.setItem('user', JSON.stringify(response))
            localStorage.removeItem('is-email-activation-link-sent')        
            navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER)
        })
        .catch(error => {  
            const errorMessage = (error.response && error.response.data) 
                || error.title || error.toString()   
            if(errorMessage === 'Invalid token.') {
                setHasTokenExpired(true) 
            } else {
                setIsError(true)
            }                      
            setIsLoading(false)
        })   
    }, [token, userId, navigate, dispatch])

    const handleLinkClicked = (e) => {
        e.preventDefault()
        localStorage.removeItem('is-email-activation-link-sent')
        navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL)
    }

    return (
        <Fragment>
            <Helmet>
                <title>KREDU - Bestätigung der E-Mail Adresse</title>
                <meta name="description" content="KREDU - Bestätigung der E-Mail Adresse" />
                <meta name="robots" content="noindex,nofollow" /> 
            </Helmet>
            <div className='vh-100 pt-lg-3 container'>
            <div className={`${classes.registrationFormBox} d-md-inline-block ms-lg-3`}>
                <div className='text-center box-width'>
                    <FontAwesomeIcon
                    icon={faEnvelopeCircleCheck}
                    fontSize='30px'
                    className='me-3 font-blue d-inline-block'
                    />
                    <h3 className='mb-5 d-inline-block'>Bestätigung der E-Mail Adresse</h3>                     
                    <br />
                    {isLoading && 
                    <MDBSpinner
                    className='font-blue mb-4 mx-auto' style={{ width: '4rem', height: '4rem' }}
                    >
                    <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                    }
                    {hasTokenExpired &&
                    <div>
                    <p className={`mx-auto ${classes.pWidth}`}>
                        Dein Aktivierungslink ist bereits abgelaufen. Er ist nur noch für 15
                        Minuten gültig. Geh zurück zur Registrierung, um einen neuen Aktivierungslink
                        an deine E-Mail-Adresse zu senden.
                    </p>
                    <Link to='/registrierung/email' onClick={handleLinkClicked} className={classes.registrationLink}>
                        <FontAwesomeIcon
                        icon={faArrowLeft}
                        fontSize='20px'
                        className='me-2'
                        />
                        Zurück zur Registrierung
                    </Link>                
                    </div>
                    }
                    {isError && <FormErrors />}
                </div>
                
            </div>
            </div>
        </Fragment>
    )
}

export default RegistrationEmailConfirmation
