import { Fragment } from 'react'

import { MDBCard } from 'mdb-react-ui-kit'

import classes from '../assets/css/CardCreated.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudExclamation } from '@fortawesome/pro-light-svg-icons'

function CardCreated({
  productName,
  price,
  serialNumber,
  last4Digits,
  expiryMonth,
  cvc,
  daysLeft,
  cardImageBase64,
  cardBalance,
}) {
  return (
    <Fragment>
      <div className='text-center'>
        {cardImageBase64 !== null ? (
          <img
            src={`data:image/octet-stream;base64,${cardImageBase64}`}
            alt='voucher'
            className={`${classes.voucher} mb-3 img-fluid`}
          />
        ) : (
          <MDBCard className='mb-3 p-4 text-muted'>
            <FontAwesomeIcon
              icon={faCloudExclamation}
              fontSize='24px'
              className='mb-2'
              size='2xl'
            />
            <p className={`${classes.small} text-muted mx-auto  mb-0`}>
            Die Daten deiner Mastercard sind vorübergehend nicht verfügbar. Bitte versuche
              es in Kürze erneut oder kontaktiere unseren Support.
            </p>
          </MDBCard>
        )}
      </div>

      <div className='d-flex justify-content-between mb-3 col'>
        <span className=''>{productName}</span>
        <span>{price.toFixed(2).replace('.', ',')} €</span>
      </div>
      {last4Digits !== null && expiryMonth !== null && (
        <div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>Saldo</span>
            <span className='s-text'>
              {cardBalance !== null
                ? cardBalance.toFixed(2).replace('.', ',') + ' €'
                : 'Vorübergehend nicht verfügbar'}
            </span>
          </div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>Letzten 4 Ziffern</span>
            <span className='s-text'>{last4Digits}</span>
          </div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>Gültigkeit</span>
            <span className='s-text'>
              Noch {daysLeft} Tage ({expiryMonth})
            </span>
          </div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>CVC</span>
            <span className='s-text'>{cvc}</span>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CardCreated
