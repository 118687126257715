import classes from './RegistrationMain.module.css'

function RegistrationMain( {children} ) {
  return (
    <main className={`${classes.mainScreen} bg-grey d-md-flex pt-0 h-100`} id='registration'>
      {children}
    </main>
  )
}

export default RegistrationMain