import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/identification'

const createWebIdAction = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL, {}, config)
    return response.data
}

const identificationService = {    
    createWebIdAction
}

export default identificationService

