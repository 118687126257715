export const mapOrderStatusToUserFriendlyText = (orderStatus) => {
    switch (orderStatus) {        
        case 'PROCESSING':
        case 'ON_HOLD':
            return 'In Bearbeitung'
        case 'PENDING':
            return 'Zu bezahlen'
        case 'COMPLETED':
            return 'Bezahlt'
        case 'CANCELLED':
            return 'Widerrufen'
        case 'REFUNDED':
            return 'Rückerstattet'
        default: 
            throw new Error('Unknown order status')
    }
}