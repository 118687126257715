import { format } from 'date-fns'
import { ROUTES } from '../../../../data/constants'
import { mapOrderStatusToUserFriendlyText } from '../../../../common/HelperFunctions'

import classes from '../assets/css/Order.module.css'

function Order({ order }) {
  return (
    <div className={`${classes.paymentBox} py-3 col-12`}>
        <div className='d-flex justify-content-between'>
        <a href={`${ROUTES.DASHBOARD}/${ROUTES.DASHBOARD_ORDER}/${order.orderId}`} className='font-blue order-nr'>
            Bestellung {order.orderId}
        </a>
        <span className='to-pay'>
            {order.totalOrderAmount.toFixed(2).replace('.', ',')} €
        </span>
        </div>
        <div className='d-flex justify-content-between'>
        <span className='text-muted order-date s-text'>
            {format(new Date(order.orderDate), 'dd.MM.yyyy HH:mm')}
        </span>
        <span className='pay-status text-muted s-text'>
          {mapOrderStatusToUserFriendlyText(order.orderStatus)}
        </span>
        </div>
    </div>
  )
}

export default Order
