import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ROUTES } from './data/constants'

import Homepage from './pages/general/Homepage'
import Contact from './pages/general/Contact'
import FAQ from './pages/general/FAQ'
import Revocation from './pages/general/Revocation'
import Imprint from './pages/general/Imprint'
import AllProducts from './pages/general/AllProducts'
import Product from './pages/general/Product'
import Error404Page from './pages/general/Error404Page'
import Error500Page from './pages/general/Error500Page'
import DefaultLayout from './layouts/DefaultLayout'
import DefaultLayoutNoFooter from './layouts/DefaultLayoutNoFooter'
import HomepageLayout from './layouts/HomepageLayout'
import RegistrationLayout from './layouts/RegistrationLayout'
import DashboardLayout from './layouts/DashboardLayout'
import CartLayout from './layouts/CartLayout'
import DashboardNavigationOnlyDesktopLayout from './layouts/DashboardNavigationOnlyDesktopLayout'
import Vendor from './pages/general/Vendor'
import ShoppingCart from './features/cart/index'
import Checkout from './features/checkout/index'
import RegistrationEmail from './pages/registration/RegistrationEmail'
import RegistrationEmailConfirmation from './pages/registration/RegistrationEmailConfirmation'
import RegistrationEmailActivationLinkSent from './pages/registration/RegistrationEmailActivationLinkSent'
import RegistrationMobilePhoneNumber from './pages/registration/RegistrationMobilePhoneNumber'
import RegistrationOTP from './pages/registration/RegistrationOTP'
import RegistrationPersonalInformation from './pages/registration/RegistrationPersonalInformation'
import RegistrationAdditionalInformation from './pages/registration/RegistrationAdditionalInformation'
import UserDeclined from './pages/registration/UserDeclined'
import BnplOrderDeclined from './pages/registration/BnplOrderDeclined'
import OrderPending from './pages/registration/OrderPending'
import UnverifiedIdentity from './pages/registration/UnverifiedIdentity'
import RegistrationIDVerification from './pages/registration/RegistrationIDVerification'
import RegistrationAdditionalDocumentsMessage from './pages/registration/RegistrationAdditionalDocumentsMessage'
import AdditionalDocumentsUpload from './features/registration/additionalDocumentsUpload'
import RegistrationBankVerification from './pages/registration/RegistrationBankVerification'
import BankVerification1Cent from './features/registration/bankVerification1Cent/'
import RegistrationBankVerificationPending from './pages/registration/RegistrationBankVerificationPending'
// import RegistrationAdditionalDocuments from './pages/registration/RegistrationAdditionalDocuments'
// import RegistrationAdditionalDocumentsVerification from './pages/registration/RegistrationAdditionalDocumentsVerification'
import OrderSuccessful from './pages/registration/OrderSuccessful'
import SignIn from './pages/general/SignIn'
import MagicLinkSent from './pages/general/MagicLinkSent'
import PaymentExpired from './pages/registration/PaymentExpired'
import PaymentCanceled from './pages/registration/PaymentCanceled'
import Authentication from './pages/general/Authentication'
import DashboardOrderHistory from './features/dashboard/orderHistory/index'
import DashboardOrder from './features/dashboard/orderDetails/index'
import DashboardPersonalInformation from './pages/dashboard/DashboardPersonalInformation'
import DashboardPersonalInformationEdit from './pages/dashboard/DashboardPersonalInformationEdit'
import ScrollToTop from './components/ScrollToTop'
import PrivateRoute from './common/PrivateRoute'
import AuthVerify from './common/AuthVerify'
import RegistrationAndOrderGuard from './common/RegistrationAndOrderGuard'


const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <AuthVerify />
          <Routes>
            <Route path='/' element={<HomepageLayout />} exact>
              <Route path='/' element={<Homepage />} exact />
            </Route>
            <Route path='/' element={<DefaultLayout />} exact>
              <Route path={ROUTES.ALL_PRODUCTS} element={<AllProducts />} />
              <Route path={`${ROUTES.VENDOR}/:slug`} element={<Vendor />} />
              <Route
                path={`${ROUTES.PRODUCT_DETAILS}/:slug`}
                element={<Product />}
                exact
              />
              <Route path={ROUTES.SIGN_IN} element={<SignIn />} exact />
              <Route
                path={ROUTES.MAGIC_LINK_SENT}
                element={<MagicLinkSent />}
                exact
              />
              <Route
                path={ROUTES.MAGIC_LINK_CONFIRMATION}
                element={<Authentication />}
                exact
              />
              <Route path={ROUTES.CONTACT} element={<Contact />} exact />
              <Route path={ROUTES.FAQ} element={<FAQ />} exact />
              <Route path={ROUTES.REVOCATION} element={<Revocation />} exact />
              <Route path={ROUTES.IMPRINT} element={<Imprint />} exact />
              <Route path={ROUTES.ERROR_500} element={<Error500Page />} exact />

              <Route
                path={ROUTES.PAYMENT_EXPIRED}
                element={<PaymentExpired />}
                exact
              />
              <Route
                path={ROUTES.PAYMENT_CANCELED}
                element={<PaymentCanceled />}
                exact
              />              
            </Route>

            <Route path='/' element={<DefaultLayoutNoFooter />} exact>
              <Route
                path={ROUTES.ORDER_SUCCESSFUL}
                element={<OrderSuccessful />}
                exact
              />
            </Route>

            <Route path='/' element={<DefaultLayout />} exact>
              <Route path='/' element={<RegistrationAndOrderGuard />}>
                <Route
                  path={ROUTES.USER_DECLINED}
                  element={<UserDeclined />}
                  exact
                />
                <Route
                  path={ROUTES.BNPL_ORDER_DECLINED}
                  element={<BnplOrderDeclined />}
                  exact
                />
                <Route
                  path={ROUTES.ORDER_PENDING}
                  element={<OrderPending />}
                  exact
                />
                <Route
                  path={ROUTES.UNVERIFIED_IDENTITY}
                  element={<UnverifiedIdentity />}
                  exact
                />
              </Route>
            </Route>

            <Route path='/' element={<CartLayout />} exact>
              <Route path={ROUTES.CART} element={<ShoppingCart />} />
              <Route path='/' element={<RegistrationAndOrderGuard />}>
                <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
              </Route>
            </Route>

            <Route
              path={ROUTES.REGISTRATION}
              element={<RegistrationAndOrderGuard />}
            >
              <Route
                path={ROUTES.REGISTRATION}
                element={<RegistrationLayout />}
                exact
              >
                <Route
                  path={ROUTES.REGISTRATION_EMAIL}
                  element={<RegistrationEmail />}
                  exact
                />
                <Route
                  path={ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT}
                  element={<RegistrationEmailActivationLinkSent />}
                  exact
                />
                <Route
                  path={ROUTES.REGISTRATION_EMAIL_CONFIRMATION}
                  element={<RegistrationEmailConfirmation />}
                  exact
                />
                <Route
                  path={ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER}
                  element={<RegistrationMobilePhoneNumber exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_OTP_CONFIRMATION}
                  element={<RegistrationOTP exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_BANK_VERIFICATION}
                  element={<BankVerification1Cent exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_BANK_VERIFICATION_PENDING}
                  element={<RegistrationBankVerificationPending exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_PERSONAL_DATA}
                  element={<RegistrationPersonalInformation exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA}
                  element={<RegistrationAdditionalInformation exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_ADDITIONAL_DOCUMENTS}
                  element={<AdditionalDocumentsUpload exact />}
                />
                <Route
                  path={ROUTES.REGISTRATION_IDENTIFICATION}
                  element={<RegistrationIDVerification exact />}
                />
              </Route>
            </Route>

            <Route path={ROUTES.DASHBOARD} element={<PrivateRoute />}>
              <Route path={ROUTES.DASHBOARD} element={<DashboardLayout />}>
                <Route
                  path={ROUTES.DASHBOARD_USER_PROFILE}
                  element={<DashboardPersonalInformation />}
                  exact
                />
                <Route
                  path={ROUTES.DASHBOARD_ORDERS}
                  element={<DashboardOrderHistory />}
                  exact
                />
              </Route>
            </Route>

            <Route path={ROUTES.DASHBOARD} element={<PrivateRoute />}>
              <Route
                path={ROUTES.DASHBOARD}
                element={<DashboardNavigationOnlyDesktopLayout />}
              >
                <Route
                  path={`${ROUTES.DASHBOARD_ORDER}/:id`}
                  element={<DashboardOrder />}
                  exact
                />
              </Route>
            </Route>

            <Route path='*' element={<DefaultLayout />}>
              <Route path='*' element={<Error404Page />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>

      <ToastContainer />
    </>
    /* <Vendor /> */
    /* <ShoppingCartScreen /> */
    /* <RegistrationEmail /> */
    /* <LinkSent /> */
    /* <RegistrationMobilePhoneNumber /> */
    /* <RegistrationOTP /> */
    /* <RegistrationPersonalInformation /> */
    /* <RegistrationAdditionalInformation /> */
    /* <RegistrationAddress /> */
    /* <RegistrationAddressManual /> */
    /* <RegistrationDetailsConfirmation /> */
    /* <ApplicationRejected /> */
    /* <ApplicationInProcess /> */
    /* <RegistrationIDVerification /> */
    /* <RegistrationAdditionalDocuments /> */
    /* <RegistrationAdditionalDocumentsVerification /> */
    /* <RegistrationSummary /> */
    /* <RegistrationOrderSuccessful /> */
    /* <DashboardOrderHistory /> */
    /* <DashboardOrder /> */
    /* <DashboardInvoices /> */
    /* <DashboardPersonalInformation /> */
    /* <DashboardPersonalInformationEdit /> */
  )
}

export default App
