import { MDBCard } from 'mdb-react-ui-kit'

function NoInvoicesYet() {
  return (
    <MDBCard className='mb-5 px-3 py-4  mx-xl-0 mx-sm-5 mx-1'>
      <p className='text-muted'>Die Rechnung für diese Bestellung ist noch nicht verfügbar. Du wirst sie hier sehen, sobald wir die Bearbeitung deiner Bestellung abgeschlossen haben.</p>
    </MDBCard>
  )
}

export default NoInvoicesYet