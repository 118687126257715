import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../data/constants'

const PrivateRoute = () => {
    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {     
    }, [user])
    
    return user !== null ? <Outlet /> : <Navigate to={ROUTES.SIGN_IN} />
}

export default PrivateRoute