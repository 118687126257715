import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/payment'

const updateLastPaymentStatus = async (token, status) => {       
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(API_URL, {           
        status: status
    }, config)          
    return response.data
}

const paymentService = {    
    updateLastPaymentStatus   
}

export default paymentService

