import { useState, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import userService from '../../services/userService'
import { ROUTES } from '../../data/constants'

import { MDBInput, MDBBtn, MDBCheckbox, MDBSpinner } from 'mdb-react-ui-kit'
import FormError from '../../components/FormError'

import classes from './RegistrationEmail.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons'
import { is } from 'date-fns/locale'

function RegistrationEmail() {
  const [email, setEmail] = useState('')
  const [wantsNewsletter, setWantsNewsletter] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const { paymentMethod } = useSelector((state) => state.cart)

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toString())
  }

  const handleNewsletterCheckboxChange = (e) => {
    setWantsNewsletter(e.target.checked)
  }

  const handleFormSubmit = (e) => {
    if(isLoading) return
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    userService
      .registerUser(email, wantsNewsletter, paymentMethod)
      .then((response) => {
        localStorage.setItem('is-email-activation-link-sent', true)
        navigate(
          `${ROUTES.REGISTRATION}/${ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT}?email=${email}`
        )
      })
      .catch((error) => {
        setIsError(true)
        if (error.response.status === 409) {
          setErrorMessage(
            'Diese E-Mail-Adresse wird bereits benutzt. Wenn du bereits ein Konto hast, melde dich bitte an.'
          )
        }
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - E-Mail-Adresse</title>
        <meta name='description' content='KREDU - E-Mail-Adresse' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 pt-lg-3 container'>
        <div
          className={`${classes.registrationFormBox} d-md-inline-block ms-lg-3`}
        >
          <p
            className={`${classes.pRegistration} fw-normal text-md-center ps-3 ps-md-0 mb-4`}
          >
            Hast du bereits ein Kundenkonto bei uns? Dann <br /> logge dich ein:
            <Link className={classes.fontBlue} to={ROUTES.SIGN_IN}>
              <FontAwesomeIcon
                icon={faRightToBracket}
                fontSize='18px'
                className='mx-2 font-blue'
              />
              <span className='font-blue'>Anmeldung</span>
            </Link>
          </p>
          <p
            className={` pt-md-0 text-md-center ps-3 ps-md-0 mb-5 ${classes.pRegistration}`}
          >
            Wir benötigen deine E-Mail-Adresse, um loszulegen.
          </p>
          <form
            onSubmit={handleFormSubmit}
            className={`${classes.formBox} form-box px-3 px-md-0`}
          >
            <MDBInput
              required
              label='E-Mail'
              type='email'
              id='registrationEmail'
              className={`form-control mb-5 form-outline ${classes.formControl}`}
              value={email}
              onChange={handleEmailChange}
            ></MDBInput>
            <div className='d-flex mb-1 ps-2'>
              <MDBCheckbox
                required
                name='flexCheck'
                value=''
                id='newsletterAgreement'
              />
              <p className='fw-normal'>
                Ich stimme den{' '}
                <a
                  className={classes.fontBlue}
                  href='../docs/KREDU_AGB_02_2023.pdf'
                >
                  {' '}
                  AGB
                </a>{' '}
                und{' '}
                <a
                  href='../docs/KREDU_Datenschutzerklaerung.pdf'
                  className={classes.fontBlue}
                >
                  Datenschutzbestimmungen
                </a>{' '}
                zu.
              </p>
            </div>
            <div className='d-flex mb-2 ps-2'>
              <MDBCheckbox
                name='flexCheck'
                onChange={handleNewsletterCheckboxChange}
                id='newsletterAgreement'
              />
              <p className='fw-normal'>
                Ja, ich will Angebote und Neuigkeiten von KREDU erhalten.
              </p>
            </div>
            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              variant='light'
              type='submit'
              className={`${classes.btnYellow} btn-yellow btn w-100  mx-auto mt-2 waves-effect`}
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Weiter
            </MDBBtn>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationEmail
