import { useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import Cart from './components/Cart'
import EmptyCart from './components/EmptyCart'

function ShoppingCart() {
  const { cartItems } = useSelector((state) => state.cart)

  useEffect(() => {}, [cartItems])

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Warenkorb</title>
        <meta name='description' content='KREDU - Warenkorb' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      {Array.isArray(cartItems) && cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <Cart cartItems={cartItems} />
      )}
    </Fragment>
  )
}

export default ShoppingCart
