import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productService from '../services/productService'

const initialState = {
  products: [],
  vendor: null,
  productDetails: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null
}

export const loadAllProducts = createAsyncThunk(
  'products/loadAllProducts',
  async (params, thunkAPI) => {
    try {        
      return await productService.loadProductData()
    } catch (error) { 
      const errorMessage = 
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const loadVendorData = createAsyncThunk(
  'products/loadVendorData',
  async (slug, thunkAPI) => {
    try {
      return await productService.loadVendorData(slug)
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const loadProductDetails = createAsyncThunk(
  'products/loadProductDetails',
  async (slug, thunkAPI) => {
    try {
      return await productService.loadProductDetails(slug)
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: { 
      reset: (state) => {
        state.products = []
        state.vendor = null
        state.productDetails = null
        state.isLoading = false        
        state.isError = false       
        state.isSuccess = false        
      }
    },
    extraReducers: builder => {
        builder
        .addCase(loadAllProducts.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(loadAllProducts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products = action.payload
        })
        .addCase(loadAllProducts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.payload
        })
        .addCase(loadVendorData.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(loadVendorData.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.vendor = action.payload
        })
        .addCase(loadVendorData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.payload
        })
        .addCase(loadProductDetails.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(loadProductDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.productDetails = action.payload
        })
        .addCase(loadProductDetails.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.payload
        })
    } 
})

export const {
  reset  
} = productsSlice.actions
export default productsSlice.reducer