import classes from '../assets/css/CartError.module.css'

import errorImg from '../../../assets/img/error-img.svg'

function CartError() {
  function handleButtonRefresh() {
    window.location.reload(false)
  }

  return (
    <div className='row text-center'>
      <h2 className={`fw-bold mb-5 ${classes.marginTop}`}>
        oh nein, da ist etwas schief gelaufen
      </h2>
      <div className={` ${classes.errorBox} mx-auto col`}>
        <img src={errorImg} alt='Error' className='img-fluid mb-4'></img>
        <h4 className='fw-bold mt-2'>
          Beim Laden deines Warenkorbs ist ein Fehler aufgetreten.
        </h4>{' '}
        <p>
          Bitte versuche erneut, diese Seite zu laden oder kontaktiere unseren
          Support unter{' '}
          <a
            href='mailto:support@kredu.de'
            className='text-decoration-underline'
          >
            support@kredu.de
          </a>
          , wenn das Problem weiterhin besteht.
        </p>
        <button onClick={handleButtonRefresh} className={classes.link}>
          {' '}
          Erneut versuchen
        </button>
      </div>
    </div>
  )
}

export default CartError
