import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './assets/css/index.css'
import './assets/css/shoppingCart.css'
import './assets/css/responsive.css'
import './assets/css/registration.css'
import './assets/css/dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import reportWebVitals from './utils/reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
