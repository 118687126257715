import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  finishedSteps: []
}

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {    
    setFinishedSteps: (state, action) => {
      state.finishedSteps = action.payload
    }
  }  
})

export const { setFinishedSteps } =
registrationSlice.actions
export default registrationSlice.reducer