import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/required-documents'

const getRequiredDocuments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(API_URL, config)
  return response.data
}

const requiredDocumentsService = {  
  getRequiredDocuments,
}

export default requiredDocumentsService
