import { Fragment } from 'react'

function CardNotYetCreated({productName, price, quantity}) {
  return (
    <Fragment>
      <div className='d-flex justify-content-between mb-3 col'>
        <span className=''>{productName}</span>
        <span>{(price * quantity).toFixed(2).replace('.',',')} €</span>
      </div>
      <div className=' mb-2 col'>
        <span className='s-text text-muted'>Deine Mastercard-Daten sind hier sichtbar, sobald die Rechnung erstellt ist.</span>
      </div>
      <div className='payment-box mb-3 pb-1 col'>
        <span className='text-muted s-text'>Anzahl: {quantity}</span>
      </div>
    </Fragment>
  )
}

export default CardNotYetCreated