import {Fragment} from 'react'

function Content( {children} ) {
  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default Content