import { Fragment } from 'react'
import { format } from 'date-fns'
import classes from '../assets/css/AdditionalDocumentsUpload.module.css'

const DocumentsList = ({ documents }) => {
  const getVerificationStatusName = (status) => {
    if (status === 'ACCEPTED') {
      return 'Akzeptiert'
    } else if (status === 'REJECTED') {
      return 'Abgelehnt'
    } else if (status === 'PENDING') {
      return 'Wird geprüft'
    }
  }

  const getVerificationStatusColor = (status) => {
    if (status === 'ACCEPTED') {
      return classes.statusAccepted
    } else if (status === 'REJECTED') {
      return classes.statusRejected
    } else if (status === 'PENDING') {
      return classes.statusInProgress
    }
  }

  const getDocumentTypeName = (status) => {
    switch (status) {
      case "REGISTRATION_CERTIFICATE":
        return "Meldebescheinigung";
      case "RESIDENCE_PERMIT":
        return "Aufenthaltstitel";
      case "INVOICE":
        return "Rechnung";
      case "PAYROLL":
        return "Gehaltsabrechnung";
        case "PERSONAL_ID":
      return "Ausweis";
    case "PASSPORT":
      return "Reisepass";
      default:
        return "Unbekannt";
    }
  }

  return (
    <Fragment>
      {documents.map((document) => {
        return (
          <div key={document.id} className={`mb-4 ${classes.documentRow}`}>
            <div className='pb-1 d-lg-flex justify-content-between'>
              <div className={`${classes.fileName}`}>{document.fileName}</div>
              <div className=' small text-lg-end'>
                {format(new Date(document.uploadedAt), 'dd.MM.yyyy HH:mm')}
              </div>
            </div>
            <div className='d-flex justify-content-between pb-0'>
              <p className='text-muted fw-normal mb-1 small'>
                {getDocumentTypeName(document.documentType)}
              </p>
              <div
                className={`${getVerificationStatusColor(
                  document.verificationStatus
                )} small ms-1`}
              >
                {getVerificationStatusName(document.verificationStatus)}
              </div>
            </div>
            {document.rejectionReason && (
              <p className='fw-normal small text-muted mb-1'>
                {document.rejectionReason}
              </p>
            )}
          </div>
        )
      })}
    </Fragment>
  )
}

export default DocumentsList
