import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MDBBtn } from 'mdb-react-ui-kit'
import classes from './assets/css/AddProductToCart.module.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { addToCart, reset } from '../../cart/redux/cartSlice'

function AddProductToCart({
  productId,
  cartProductId,
  slug,
  isInStock,
  children,
}) {
  const [isAddedToCart, setIsAddedToCart] = useState(false)

  const dispatch = useDispatch()

  const { isLoading, isSuccess, isError, message, recentlyAddedCartProductId } =
    useSelector((state) => state.cart)

  useEffect(() => {
    if (recentlyAddedCartProductId === cartProductId) {
      if (isError) {
        dispatch(reset())
      }

      if (isSuccess) {
        setIsAddedToCart(true)
        dispatch(reset())
      }
    } else {
      setIsAddedToCart(false)
    }
  }, [isSuccess, isError, message, recentlyAddedCartProductId, dispatch])

  const addToCartHandler = () => {
    const cartItem = {
      productId: productId,
      quantity: 1,
      recentlyAddedCartProductId: cartProductId,
      name: null,
    }

    dispatch(addToCart(cartItem))
  }

  return (
    <div className={`${classes.cardBox} text-center mx-auto`}>
      {children}

      <div
        className={`${classes.cardControls} text-center d-flex flex-column  justify-content-center `}
      >
        <Link to={`/produkt/${slug}`} className={`${classes.detailsLink} mb-3`}>
          Details ansehen
        </Link>
        <div className=' d-flex  justify-content-center mb-2'>
          {isInStock === true ? (
            <MDBBtn className={classes.btnYellow} onClick={addToCartHandler}>
              In den Warenkorb
              <FontAwesomeIcon
                icon={faCheck}
                fontSize='17px'
                className={`ms-3 ${isAddedToCart ? '' : 'd-none'}`}
              />
            </MDBBtn>
          ) : (
            <MDBBtn disabled className={`${classes.outOfStockBtn}`}>
              {slug.startsWith("kredupay") ? "Demnächst wieder verfügbar" : "Nicht vorrätig"}
            </MDBBtn>
          )}
        </div>
        <div>
          <Link className={isAddedToCart ? '' : 'd-none'} to='/warenkorb'>
            Warenkorb anzeigen
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AddProductToCart
