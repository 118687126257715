

import classes from '../assets/css/NoOrderHistoryCard.module.css'

import noInvoices from '../../../../assets/img/no-invoices.svg'

import arrowForward from '../../../../assets/img/arrow-forward-blue.svg'

function NoOrderHistoryCard() {
  return (
    <div className={`${classes.card} text-center`}>
      <img src={noInvoices} alt='no-invoices' className={`${classes.icon} mb-5 img-fluid mx-auto`} />
      <p className={`${classes.text} mx-auto px-1 mb-5`}>Du hast noch keine Bestellungen. Klicke auf den Link unten, um mit dem Einkauf zu beginnen.</p>
      <a className={classes.link} href='/alle-produkte'>Zu allen Produkten <img src={arrowForward} alt='forward' /> </a>
      </div>
  )
}

export default NoOrderHistoryCard