import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/auth/'

const requestMagicLink = async (email) => {
  const response = await axios.post(API_URL + 'request-magic-link', {
    email: email,
  })
  localStorage.setItem('is-magic-link-sent', true)
  return response
}

const getToken = async (token, userId) => {
  const response = await axios.post(API_URL + 'get-token', {
    token: token,
    userId: userId,
  })
  return response.data
}

const authService = {
  requestMagicLink,
  getToken,
}

export default authService
