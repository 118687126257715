import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit'

import classes from './FAQ.module.css'

function FAQ() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Häufig gestellte Fragen</title>
        <meta name='description' content='KREDU - Häufig gestellte Fragen' />
      </Helmet>
      <section className={`${classes.faq} pt-5`}>
        <div className='container-lg'>
          <h2 className='text-center mt-md-5 pt-5 pb-4 pb-md-5 fw-bold'>
            Häufig gestellte Fragen
            <br /> (FAQ)
          </h2>
          <h3 className='text-center pb-3 fw-bold'>
            Kauf von Gutscheinen bei KREDU
          </h3>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 col-12'>
              <MDBAccordion
                flush
                alwaysOpen
                initialActive={1}
                className={classes.faqItem}
              >
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle='Ich wurde für den Rechnungskauf abgelehnt und verstehe nicht wieso'
                >
                  Wurdest du abgelehnt, bedeutet dies meistens, dass die
                  Kriterien für den Rechnungskauf bzw. die Vorgaben unserer
                  Bonitätsabteilung nicht erfüllt wurden. In den meisten Fällen
                  sind es negative Schufa Einträge oder ein zu geringes
                  Einkommen, die zu einer Ablehnung führen. Du kannst dich dazu
                  direkt an die Schufa wenden und eine Eigenauskunft beantragen.
                  Das kannst du hier tun:
                  https://www.meineschufa.de/de/datenkopie
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle='Ich habe eine negative Schufa. Kann ich trotzdem einen Gutschein erwerben?
                  '
                >
                  Du hast trotzdem die Möglichkeit einen Gutschein per Vorkasse
                  zu erwerben. Den Kauf auf Rechnung können wir dir aber leider
                  nicht zur Verfügung stellen.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle='Wie erhalte ich meine Rechnung bzw. den Gutscheincode?'
                >
                  Nach erfolgreicher Bestellung und Annahme durch uns erhältst
                  du eine E-Mail mit einem PDF-Dokument im Anhang. Auf dieser
                  Rechnung findest du den jeweiligen Gutscheincode.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={4}
                  headerTitle='Ab wann ist der Gutschein gültig?
                  '
                >
                  Sobald du die Rechnung erhalten hast, auf dem der
                  Gutscheincode aufgeführt ist, kannst du diesen auch einlösen.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={5}
                  headerTitle='Wie lange dauert es, bis ich den Gutschein erhalte?
                  '
                >
                  Wir sind stets bemüht, deine Bestellungen schnellstmöglich
                  abzuarbeiten, um kurze Lieferzeiten zu ermöglichen. Die
                  Wartezeit für den Erhalt deiner Bestellung beträgt in der
                  Regel ein paar Minuten, dies kann am Wochenende sowie an
                  Feiertagen jedoch variieren. <br />
                  Gelegentlich kann es zu technischen Verzögerungen kommen und
                  sich dadurch die Auslieferung verspäten. Sollte das der Fall
                  sein so können Sie uns per Email oder Chat kontaktieren.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={6}
                  headerTitle='Was passiert, wenn ich meine Rechnungen nicht bezahle (und auch keine Stundung für die Rechnung erhalten habe)?
                  '
                >
                  Wir bitten dich, deine Rechnungen fristgerecht zu entrichten.
                  Ist dir dies jedoch nicht möglich, kontaktieren uns bitte
                  frühzeitig und wir versuchen eine Lösung zu finden die
                  eventuell dann anfallenden Inkassogebühren so gering wie
                  möglich zu halten. <br />
                  Wenn die Rechnungen jedoch nicht fristgerecht entrichtet
                  werden, müssen wir die überfällige Forderung zur weiteren
                  Betreibung an ein Inkassounternehmen übergeben. Die Abwicklung
                  ist für uns mit einem sehr hohen Aufwand verbunden und für
                  dich verursacht dies weitere Kosten, welche dir direkt von dem
                  Inkassounternehmen in Rechnung gestellt werden.
                </MDBAccordionItem>
              </MDBAccordion>
              <h3 className='text-center pb-3 pt-5 fw-bold'>
                Stornierung, ungültige Gutscheine und andere Probleme
              </h3>
              <MDBAccordion
                flush
                alwaysOpen
                initialActive={2}
                className={classes.faqItem}
              >
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle='Kann ich meine Gutscheinbestellung stornieren?'
                >
                  Solange noch keine Rechnung und somit kein Code versendet
                  wurde, kannst du deine Bestellung jederzeit stornieren. Sende
                  uns bitte eine E-Mail mit Angabe der Rechnungsnummer und dem
                  Wunsch auf Stornierung an info@kredu.de. <br />
                  Sollte sich dein Stornierungswunsch mit dem Versand
                  überschneiden, ist eine Stornierung leider nachträglich nicht
                  möglich.
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={3}
                  headerTitle='Mein Gutschein ist ungültig. Was kann ich tun?
                  '
                >
                  Wir verkaufen ausschließlich neue Gutscheine. Trotz aller
                  Prüfung kann es natürlich zu Fehlern kommen. Bitte wende dich
                  in diesem Fall per E-Mail an info@kredu.de und nenne uns
                  unbedingt die Rechnungsnummer sowie die Seriennummer des
                  Gutscheins.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={4}
                  headerTitle='Ich habe eine Frage zu meiner Rechnung/Mahnung?

                  '
                >
                  Bei aufkommenden Fragen kannst du dich jederzeit per E-Mail:{' '}
                  <a
                    href='mailto:support@kredu.de'
                    className={` ${classes.fontBlue}  d-inline-block`}
                  >
                    support@kredu.de
                  </a>{' '}
                  an uns wenden. Gerne prüfen wir dein Anliegen und beantworten
                  dir deine Fragen.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={5}
                  headerTitle='Wie kann ich meine Daten ändern?
                  '
                >
                  Hier kommt es ganz darauf an, welche Daten du Ändern möchtest.
                  Deine hinterlegte Anschrift, E-Mail-Adresse und Rufnummer
                  kannst du jederzeit selbstständig in deinem Kundenkonto
                  anpassen. Wenn du jedoch deinen Namen anpassen möchtest (du
                  hast geheiratet oder dich bei der Anmeldung einfach vertippt)
                  benötigen wir ggf. einen Nachweis, um die Änderung auch
                  vornehmen zu können. In diesem Fall kontaktiere uns bitte per
                  E-Mail.
                </MDBAccordionItem>
              </MDBAccordion>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 col-12 text-center mb-5'>
              <h3 className='py-3 mt-4 fw-bold'>
                Meine Frage wird in den FAQ leider nicht beantwortet
              </h3>
              <p className='text-start px-4 py-3 bg-white text-muted'>
                Bei Fragen kannst du dich jederzeit per E-Mail an uns wenden:
                <a
                  href='mailto:support@kredu.de'
                  className={` ${classes.fontBlue}  d-inline-block`}
                >
                  support@kredu.de
                </a>{' '}
                <br />
                Gerne prüfen wir dein Anliegen und beantworten deine Fragen.{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default FAQ
