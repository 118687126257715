import { Helmet } from 'react-helmet-async'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../data/constants'

import sendEmail from '../../assets/img/send-email.svg'

import classes from './MagicLinkSent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

function MagicLinkSent() {
  const navigate = useNavigate()  
  const [searchParams] = useSearchParams()

  const { isMagicLinkSent } = useSelector(state => state.auth)    
  
  const email = searchParams.get('email')

  useEffect(() => {    
    if(!isMagicLinkSent) navigate('/')    
  }, [isMagicLinkSent])

    return (
    <Fragment>
      <Helmet>
          <title>KREDU - Login Link</title>
          <meta name="description" content="KREDU - Login Link" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
        <div className='vh-100 pt-lg-5 container'>
          <div className='container-heigh row'>
            <div
              className='text-center offset-sm-1 offset-md-3 col-12 col-sm-10 col-md-6 mx-auto'
            >
              <div className='text-center box-width'>
                <img
                  src={sendEmail}
                  alt='email'
                  className='mb-5 my-md-5'
                ></img>
                <p>
                  Wir haben einen Login Link an
                  <br className='d-none d-sm-block' /> <b>{email} </b>
                  gesendet. Bitte <br className='d-none d-sm-block' /> klicke
                  auf diesen Link, um fortzufahren.
                </p>
                <p>
                Falls du die E-Mail nicht finden kannst,
                  <br className='d-none d-sm-block' /> sieh bitte auch in deinem Spam-Ordner nach.
                </p>
                <Link to={ROUTES.SIGN_IN} className={classes.registrationLink}>
                <FontAwesomeIcon icon={faArrowLeft} fontSize='20px' className='me-2' /> 
                  Zurück zum Login
                </Link>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  )
}

export default MagicLinkSent
