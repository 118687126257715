import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/cart'

const submitCart = async (token, cartItems, paymentMethod) => {  
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.put(API_URL + '/submit', {         
        cartItems: cartItems,
        paymentMethod: paymentMethod        
    }, config) 
        
    return response.data
}

const revertCartSubmit = async (token) => {  
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.put(API_URL + '/revert-submit', null, config)     
    return response.data
}

const checkoutCart = async (token, paymentMethod, cartItems) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.put(API_URL + '/checkout', {
        paymentMethod: paymentMethod,
        cartItems: cartItems.filter((x) => x.isInStock === true)
    }, config)
    return response.data
}

const cartService = {
    submitCart,
    revertCartSubmit,
    checkoutCart
}

export default cartService

