import { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { requestMagicLink, reset } from '../../features/auth/authSlice'
import { MDBInput, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit'
import FormErrors from '../../components/FormError'

import { Link } from 'react-router-dom'

import classes from './SignIn.module.css'

//TODO: check if authenticated and if is then redirect to the dashboard

function SignIn() {
  const [email, setEmail] = useState('')  

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isSuccess, isError } = useSelector(state => state.auth)  

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value.toString())
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    dispatch(requestMagicLink(email))
  }

  useEffect(() => {
    if(isSuccess) {
      navigate(`/link-gesendet?email=${email}`)
    }     
    
    dispatch(reset())
  }, [isSuccess, dispatch])
  
  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Anmeldung</title>
          <meta name="description" content="KREDU - Anmeldung" /> 
        </Helmet>
    <div className='text-center pt-5 pt-md-0 mt-md-0 mt-2 vh-100 container' id='cart' >
      <div className={` ${classes.registrationFormBox} registration-form-box mx-auto`}>
        <h3 className='fw-bold mb-5'>Melde dich an.</h3>
        <p className='pt-4 pt-md-0 text-md-center ps-3 ps-md-0 mb-4'>
          Du hast noch keinen Account? <Link to='/warenkorb' className={`registrationLink ${classes.fontBlue}`} >Account erstellen</Link> 
        </p>
        <form onSubmit={handleFormSubmit} className={`${classes.formBox} form-box px-3 px-md-0`}>
          <MDBInput type='email' required 
            label='E-Mail' id='email' onChange={handleEmailInputChange}
            className={`form-outline form-control ${classes.formControl} ${classes.formOutline}`} />
          <MDBBtn
            type='submit'
            className=' btn-yellow w-100 mx-auto mt-md-3 mb-4 waves-effect'            
          >
          <MDBSpinner size='sm' role='status' tag='span' className={`me-2 ${isLoading ? '' : 'd-none'}`} />
            Weiter
          </MDBBtn>
          {isError && <FormErrors /> }
        </form>
        
      </div>
    </div>
    </Fragment>
  )
}

export default SignIn
