import { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadInitialOrders,
  loadNextPageWithOrders,
  reset,
} from '../../../redux/ordersSlice'
import { Helmet } from 'react-helmet-async'
import OrdersList from './components/OrdersList'
import { MDBCard, MDBBtn, MDBDatepicker } from 'mdb-react-ui-kit'
import NoOrderHistoryCard from './components/NoOrderHistoryCard'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { toast } from 'react-toastify'
import { ERROR_MESSAGES } from '../../../data/constants'
import DefaultReduxLoadingError from '../../../components/DefaultReduxLoadingError'

import calendar from '../../../assets/img/calendar-icon.svg'
import classes from './assets/css/OrderHistory.module.css'

function DashboardOrderHistory() {
  const [datepickerInline, setDatepickerInline] = useState('')

  const dispatch = useDispatch()
  const { orders, isLoading, isError, isNextPageLoadingError } = useSelector(
    (state) => state.orders
  )
  const [pageNumber, setPageNumber] = useState(1)

  const itemsPerPage = 6

  useEffect(() => {
    dispatch(loadInitialOrders({ itemsPerPage: itemsPerPage }))
  }, [])

  useEffect(() => {
    if (isNextPageLoadingError) {
      toast.error(ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE)
    }
  }, [isNextPageLoadingError])

  const loadMoreOrdersHandler = () => {
    const newPage = pageNumber + 1
    setPageNumber(newPage)
    dispatch(
      loadNextPageWithOrders({
        pageNumber: newPage,
        itemsPerPage: itemsPerPage,
      })
    )
  }

  if (isLoading) {
    return (
      <div
        className={`${classes.container} vh-100 pb-5 pt-5 pt-lg-3 mt-5 container`}
      >
        <div className={`mx-auto ${classes.spinnerContainer}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (isError) {
    return <DefaultReduxLoadingError />
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Bestellungen</title>
        <meta name='description' content='KREDU - Bestellungen' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div
        className={`${classes.bgGradient} ${classes.purchaseHistory} p-0 px-sm-3 p-xl-5 pt-md-5 mt-xl-5 mx-xl-auto bg-grey container-fluid`}
      >
        <MDBCard className={`mx-xl-5 mx-auto ${classes.dashboardCard}`}>
          <div className='row'>
            <div className='mb-md-5 mb-4 d-none d-lg-block col-12 col-lg-6'>
              <h2 className='fw-bold'>Bestellungen</h2>
            </div>
            <div className='mb-md-5 mb-4 ps-xl-5 col-12 col-lg-6'>
              <a href='/alle-produkte'>
                <MDBBtn className='btn-yellow w-100 text-sm-uppercase my-3 my-md-0'>
                  Gutscheine kaufen
                </MDBBtn>
              </a>

              <MDBBtn className='btn-error d-none w-100'>
                Überfällige Rechnungen zahlen
              </MDBBtn>
            </div>
            {orders.length > 0 ? (
              <OrdersList
                ordersPerPage={itemsPerPage}
                loadMoreOrdersHandler={loadMoreOrdersHandler}
              />
            ) : (
              <NoOrderHistoryCard />
            )}
          </div>
        </MDBCard>
      </div>
    </Fragment>
  )
}

export default DashboardOrderHistory
