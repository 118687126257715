import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signOut } from '../features/auth/authSlice'
import { withRouter } from './withRouter'

const parseJwt = (token) => {
  try {
    const payload = token.split('.')[1]
    return JSON.parse(atob(payload))
  } catch (e) {
    return null
  }
}

const AuthVerify = (props) => {
  const location = props.router.location

  const dispatch = useDispatch()

  useEffect(() => {
    const userString = localStorage.getItem('user')

    if (userString) {
      const user = JSON.parse(userString)
      const decodedJwt = parseJwt(user.token)

      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(signOut())
      }
    }
  }, [location, props, dispatch])

  return <div></div>
}

export default withRouter(AuthVerify)
