import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import CartNavigation from '../components/CartNavigation'
import Content from '../components/Content'


function CartLayout( {children} ) {
  return (
    <Fragment>
        <CartNavigation />
          <Content>
            {children}
            <Outlet />
          </Content>
    </Fragment>
  )
}

export default CartLayout