import axios from 'axios'

const API_URL = process.env.REACT_APP_KREDU_SHOP_API_URL + '/uploaded-documents'

const uploadRequiredDocument = async (token, file) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(API_URL, file, config)
  return response.data
}

const getUploadedDocuments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(API_URL, config)
  return response.data
}

const uploadedDocumentsService = {
  uploadRequiredDocument,
  getUploadedDocuments,
}

export default uploadedDocumentsService
