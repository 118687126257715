import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'

import inProcess from '../../assets/img/in-process-icon.svg'

import classes from './RegistrationBankVerificationPending.module.css'

function RegistrationBankVerificationPending() {
  return (
    <Fragment>
      <Helmet>
          <title>KREDU - 10-Cent-Verifizierung In Bearbeitung</title>
          <meta name="description" content="KREDU - 10 Cent Verifizierung" />
          <meta name="robots" content="noindex,nofollow" /> 
      </Helmet>
      <div className='h-100 container'>
        <div
          className={`text-center mx-auto mx-md-0 ${classes.registrationFormBox}`}
        >
          <img src={inProcess} alt='inProcess' className='mb-4'></img>
          <h2 className='mb-3 text-md-start'>10-Cent-Verifizierung in Bearbeitung</h2>
          <p className=' text-md-start mb-5 fw-normal'>
          Deine Bewerbung wird gerade bearbeitet. Bitte habe eine Weile Geduld. <br/> Wir werden dich per E-Mail informieren, wenn du mit deiner Anmeldung fortfahren kannst.
          </p>
        </div>
      </div>      
    </Fragment>
  )
}

export default RegistrationBankVerificationPending
